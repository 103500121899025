import React, { useLayoutEffect } from "react";
import Button from "../common/button";
import { completeDasURL, figmapx2vh, figmapx2vw } from "../../utils/utils";
import Limbo from "../screen/limbo";
import focus from "../../utils/focus";
import { STB } from "../../hooks/apis/stb";
import { setPopUp } from "../../actions/uiActions";
import { useDispatch, useSelector } from "react-redux";
import { TV_MODELS } from "../../utils/constants";

const TvInputs = () => {
    const dispatch = useDispatch();
    const texts = useSelector((state) => state.ui.texts);
    const tvInputs = useSelector((state) => state.status.locationData.tvInputs);

    const tvInputsNotAvailable = tvInputs.length === 0 || STB.model === TV_MODELS.GOOGLE_TV;

    const width = "100%";
    const height = "100%";
    const butonInputCustomClass = "text-center mx-auto py-2 text-2xl";
    const buttonInputCustomStyle = {
        width: figmapx2vw(208),
        height: figmapx2vh(208),
        backgroundColor: "#F5F6F8",
        color: "#2E3843",
    };

    useLayoutEffect(() => {
        if (!tvInputsNotAvailable && document.getElementById(`btn-0`)) {
            focus.value.replace(`btn-0`);
        } else {
            focus.value.replace("tvInputsLimbo");
        }
    }, []);

    const changeToInput = (input) => {
        if (input.HDMI) {
            STB.changeToInput(input.num);
        } else if (input.USB) {
            // STB change to media app (usb);
            if (STB.model === TV_MODELS.TIZEN) {
                dispatch(setPopUp("samsungUsb"));
                return;
            }
            if (STB.model === TV_MODELS.LG) {
                dispatch(setPopUp("usbSelectContent"));
                return;
            }
            STB.launchUSB();
        } else if (input.BLUETOOTH) {
            if (STB.model === TV_MODELS.TIZEN) {
                dispatch(setPopUp("samsungBluetooth"));
                return;
            } else if (!STB.supportBluetooth) {
                dispatch(setPopUp("blueToothNotAvailable"));
                return;
            }
            STB.launchBluetooth();
        }
        dispatch(setPopUp(null));
    };

    const getInputTypeAndNumber = (input) => {
        return {
            HDMI: input.indexOf("HDMI") > -1,
            USB: input.indexOf("USB") > -1,
            BLUETOOTH: input.indexOf("BLUETOOTH") > -1,
            num: parseInt(input.slice(input.length - 1, input.length)),
        };
    };

    const getIconTypeName = (faIcon) => {
        return { lib: faIcon.split("-")[0], name: faIcon.slice(faIcon.split("-")[0].length + 1, faIcon.length) };
    };

    return (
        <div
            className={`fixed  z-900 top-0 left-0`}
            style={{
                width: `${document.body.offsetWidth || window.innerWidth}px`,
                height: `${document.body.offsetHeight || window.innerHeight}px`,
                visibility: "visible",
            }}
        >
            <Limbo id={"tvInputsLimbo"} />
            <div id="pop-up-zone" className="table h-full mx-auto" style={{ width: width || "32rem" }}>
                <div
                    className={"white "}
                    style={{
                        marginTop: "30vh",
                        position: "relative",
                        height: "70vh",
                        background:
                            "linear-gradient(0deg, rgba(55, 61, 66, 0.90) 45.02%, rgba(55, 61, 66, 0.71) 66.74%, rgba(55, 61, 66, 0.08) 90.9%, rgba(55, 61, 66, 0.00) 99.3%)",
                    }}
                >
                    <div class={"table h-full w-full mx-auto"}>
                        <div className="table-cell vertical-middle">
                            <div
                                className={"text-center pb-8 text-4xl"}
                                style={{ marginTop: tvInputsNotAvailable ? "10vh" : "32vh" }}
                            >
                                {tvInputsNotAvailable ? texts["inputs-not-available"] : texts["select-tv-input"]}
                            </div>
                            <div class="mx-auto table pt-2" style="width: fit-content">
                                <div className={"numberRow"}>
                                    {!tvInputsNotAvailable &&
                                        tvInputs.map((input, index) => (
                                            <Button
                                                data={{
                                                    id: index,
                                                    outline: true,
                                                    focusClass: "underSlashFocus",
                                                    wrapperClass: "table float-left px-8",
                                                    name: input.name,
                                                    faIcon: input.icon ? getIconTypeName(input.icon) : null,
                                                    imageURL: completeDasURL(input.imageRef),
                                                    iconSize: "pl-2 text-5xl",
                                                    rounded: true,
                                                    customClass: butonInputCustomClass,
                                                    customStyle: buttonInputCustomStyle,
                                                    onClick: () => changeToInput(getInputTypeAndNumber(input.input)),
                                                }}
                                            />
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TvInputs;
