import { navigate } from "wouter/use-location";
import { eventHandler } from "../../../utils/eventHandler";
import { prepareDeviceKeyMap, resetCredentialsOnPowerOff } from "../../../utils/utils";
import { Media } from "../media";
import { keyMap } from "./keyMap";
import { philips } from "./philips";
import { PATHS, PERMISSION, TV_MODELS } from "../../../utils/constants";
import { wixp } from "./wixp";

export const STB = {
    model: TV_MODELS.PHILIPS,
    builtInCC: true,
    installFromManager: true,
    needCanvas: true,
    manageHistory: true,
    supportBluetooth: false,
    ref: "",
    forceMovieLanguage: true,
    init() {
        this.keyMap = prepareDeviceKeyMap(keyMap);
        philips.init();
        document.body.style.width = window.screen.width;
        document.body.style.height = window.screen.height;
        console.log("Init Philips");

        //get model and Firmware
        let tvInfo = philips.getFullModel();
        localStorage.setItem("tvModel", tvInfo.model);
        localStorage.setItem("firmwareVersion", tvInfo.firmware);
        this._checkCCBuiltIn(tvInfo?.model);
    },
    _checkCCBuiltIn(tvModel) {
        if (tvModel?.includes("4014")) {
            this.builtInCC = false;
            this.installFromManager = false;
            this.supportAnimations = false;
            this.navigateOnCCInstructions = false;
            this.model = TV_MODELS.PHILIPS_NO_CC;
        }
    },
    getMac(onSuccess) {
        const onSNSuccess = (sn) => {
            if (onSuccess && sn) {
                this.ref = `${this.model}-${sn}`;
                onSuccess();
            }
        };
        philips.getSerialNumber(onSNSuccess);

        const onMacSuccess = (mac) => {
            this.mac = mac;
        };
        setTimeout(function () {
            philips.getMACAddress(onMacSuccess);
        }, 250);
    },
    powerKey() {
        philips.loadInitialPower();
    },
    updatePowerState() {
        philips.getPowerState();
    },
    setVolume(volume) {
        philips.setVolume(volume, "Off");
    },
    _gotoOfflineMode() {
        philips.disableCustomDashboard();
        philips.enableLocalCustomDashboard();
    },
    powerOn: function () {
        wixp.request("PowerState", (resp) => {
            if (resp.CurrentPowerState != "On") {
                philips.setPowerState("On");
                setTimeout(function () {
                    STB.actionsOnPowerOn();
                }, 1500);
            }
        });
    },
    powerOff: function () {
        philips.setPowerState("Standby");
    },
    changeToInput(input) {
        philips.disableCustomDashboard();
        switch (parseInt(input)) {
            case 1:
                setTimeout(function () {
                    philips.changeToInput("HDMI1");
                }, 600);
                break;
            case 2:
                setTimeout(function () {
                    philips.changeToInput("HDMI2");
                }, 600);
                break;
            case 3:
                setTimeout(function () {
                    philips.changeToInput("HDMI3");
                }, 600);
                break;
            case 4:
                setTimeout(function () {
                    philips.changeToInput("SideHDMI");
                }, 600);
                break;
        }
    },
    ensureCD() {
        sessionStorage.removeItem("outOfInterface");
        philips.deActivateAll();
        philips.deActivateApplication("AutonomousApps");
        philips.deActivateApplication("Googlecast");
        philips.deActivateApplication("YouTube");
        philips.deActivateApplication("Netflix");
        philips.enableCustomDashboard();
        philips.unregisterSomeKeys();
    },
    backToInterface() {
        philips.unregisterSomeKeys();
        this.ensureCD();
        this.powerOff();
        setTimeout(function () {
            STB.powerOn();
        }, 2000);
    },
    showCC() {
        philips.showCC();
    },
    _launchApp(appName) {
        switch (appName) {
            case "Netflix":
            case "NETFLIX":
                philips.unregisterAppKeys();
                philips.activateApplication("Netflix");
                break;
            case "YouTube":
            case "YOUTUBE":
                philips.unregisterAppKeys();
                philips.activateApplication("YouTube");
                break;
            case "APPLETV":
            case "AppleTV":
                philips.unregisterAppKeys();
                philips.activateApplication("Apple TV");
            default:
                break;
        }
    },
    onKeyReceived(e, simulateKeyPress, restoreFocus) {
        let code = e.keyCode ? e.keyCode : e.which;
        let eventDetail, eventval, keyStatus;
        if (!code && e.detail) {
            eventDetail = e.detail;
            eventval = eventDetail.split(",");
            keyStatus = parseInt(eventval[1]);

            code = parseInt(eventval[0]);
        } else if (!code && e.key_code) {
            code = e.key_code;
            keyStatus = 2;
        }

        if (document.activeElement?.id === "body") {
            restoreFocus();
            return;
        }

        switch (code) {
            case 409:
                //Power key
                if (sessionStorage.getItem("outOfInterface") === "true") {
                    sessionStorage.removeItem("outOfInterface");
                    this.ensureCD();
                    this.powerOff();
                    this._resetCredentialsOnPowerOff();
                    return;
                }
                STB.powerKey();
                return;
            case 462:
                //Home key
                philips.goToInterface();

                break;
            case 458: //tv guide
                //philips.listApplications();
                //philips.enableCustomDashboard();
                break;
            case 509: //Youtube
                STB.launchApp("YouTube");
                break;
            case 505: //Netflix
                STB.launchApp("Netflix");
                break;
            case 508: //Cast
                navigate(PATHS.CCINSTRUCTIONS);
                return;
            default:
                break;
        }

        if (keyStatus == 2) {
            eventHandler.lastKeyPressCode = code;
            simulateKeyPress("", eventval[0]);
        }
    },
    _checkout() {
        philips.checkOut();
    },
    saveDataForHA(localKey, dataToStore) {
        const urlServerSaveDataForHA = "http://philips.local:2222/gridRepository/saveGrid.php";

        const keysToStore = ["connectionRdy", "continueWatching", "grid", "highAvailability"];

        let dataForHA = JSON.parse(localStorage.getItem("dataForHA"));
        if (!dataForHA) {
            dataForHA = {};
        }
        dataForHA[localKey] = dataToStore;
        localStorage.setItem("dataForHA", JSON.stringify(dataForHA));
        let _keys_rdy = 0;
        Object.keys(dataForHA).forEach((element) => {
            if (keysToStore.includes(element)) {
                _keys_rdy++;
            }
        });

        if (keysToStore.length === _keys_rdy) {
            // Send data to server to be saved
            const arrangeData = {
                grid: dataForHA.grid,
                texts: { continueWatching: dataForHA.continueWatching, connectionRdy: dataForHA.connectionRdy },
                highAvailability: dataForHA.highAvailability,
            };
            fetch(urlServerSaveDataForHA, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                },
                body: "mac=" + this.mac + "&data=" + encodeURIComponent(JSON.stringify(arrangeData)),
            });
            localStorage.removeItem("dataForHA");
        } else if (localKey === PERMISSION.HIGH_AVAILABILITY && !dataForHA.highAvailability) {
            // force send HA NO permission to be saved
            const arrangeData = {
                grid: { grid: [{ id: "no_grid" }] },
                texts: { continueWatching: null, connectionRdy: null },
                highAvailability: dataForHA.highAvailability,
            };

            fetch(urlServerSaveDataForHA, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                },
                body: "mac=" + this.mac + "&data=" + encodeURIComponent(JSON.stringify(arrangeData)),
            });
        }
    },
    ensurePMSSession(currentGuestId) {
        if (localStorage.getItem("pmsGuestId") && localStorage.getItem("pmsGuestId") !== currentGuestId?.toString()) {
            localStorage.removeItem("pmsGuestId");
            philips.checkOut();
        } else if (!localStorage.getItem("pmsGuestId")) {
            localStorage.setItem("pmsGuestId", currentGuestId);
            philips.checkIn();
        }
    },
    checkOnLine() {
        const onPhysicalNetworkStatus = (status) => {
            if (status === "DisConnected") {
                STB.gotoOfflineMode();
            }
        };
        philips.getNetworkStatus(onPhysicalNetworkStatus);
    },
    launchUSB() {
        philips.changeToUSB();
    },
    resetCredentials() {
        philips.checkOut(philips.checkIn);
    },
    _resetCredentialsOnPowerOff() {
        resetCredentialsOnPowerOff();
    },
};
