import ReactHtmlParser from "react-html-parser";
import { figmapx2vh, figmapx2vw } from "../utils/utils";
import { useSelector } from "react-redux";
import { Texts } from "../utils/texts";
import focus from "../utils/focus";
import Limbo from "./screen/limbo";
import { useEffect } from "preact/hooks";

const OutOfContent = ({ message }) => {
    const texts = useSelector((state) => state.ui.texts);
    useEffect(() => {
        focus.value.replace("contact-hotel-staff-limbo");
    }, []);

    const renderLine = (text) => {
        return (
            <div
                className={"text-4xl mx-auto table"}
                style={{
                    width: `${figmapx2vw(1458)}`,
                    height: `${figmapx2vh(90)}`,
                }}
            >
                <div id="noContent_text" className={"w-full text-gray-100 my-auto table-cell vertical-middle"}>
                    {ReactHtmlParser(text)}
                </div>
            </div>
        );
    };
    return (
        <div className="fixed w-full h-full bg-gray-900 text-gray-100 z-100">
            <Limbo id={"contact-hotel-staff-limbo"} />
            <div className="table w-full h-full">
                <div className={`table-cell vertical-middle w-full h-full text-center white text-3xl`}>
                    {renderLine(Texts.translateCap(texts, message))}
                    {renderLine(Texts.translateCap(texts, "contact-hotel-staff"))}
                </div>
            </div>
        </div>
    );
};

export default OutOfContent;
