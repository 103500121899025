import { useEffect, useState } from "preact/hooks";
import api from "../api";
import dbStylesToStylesheet from "../../../utils/dbStylesToStylesheet";
import storage from "../../../utils/storage";
import DetailsWrapper from "../details/DetailsWrapper";

const ProductComp = ({ params, config, inEdition }) => {
    const [myChannel, setMyChannel] = useState(null);
    const [myDesign, setMyDesign] = useState(null);
    const [myVariant, setMyVariant] = useState(null);
    const [myExtras, setMyExtras] = useState(null);

    useEffect(() => {
        Promise.all([
            api.shopByToken(params.roomshop),
            api.variant(params.variantId, params.roomshop),
            api.design(config.landingPage.designID),
        ])
            .then(async ([channel, variant, design]) => {
                const extras = (
                    await Promise.all((variant.extras || []).map((x) => api.extra(x.id, params.roomshop)))
                ).reduce((acc, cur) => {
                    acc[cur.id] = cur;
                    return acc;
                }, {});
                setMyChannel(channel);
                setMyVariant(variant);
                setMyExtras(extras);
                setMyDesign(design);
                config.showLoadingContent(false);
            })
            .catch((e) => {
                vendureCacheCleanup();
            });
    }, []);

    if (!myChannel && !myVariant) {
        config.showLoadingContent(true);
        return null;
    }

    return (
        <div id="sales" style={{ display: "block" }}>
            <DetailsWrapper
                channel={myChannel}
                templating={{
                    css: dbStylesToStylesheet(myDesign?.style),
                    texts: config.texts,
                    languageCode: storage.get("lang", "en"),
                    projectLanguageCode: config.projectLanguageCode,
                }}
                variant={myVariant}
                extras={myExtras}
                inEdition={inEdition}
                texts={config.texts}
            />
        </div>
    );
};

export default ProductComp;
