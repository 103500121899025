import { useEffect, useState } from "preact/hooks";
import api from "../api";
import dbStylesToStylesheet from "../../../utils/dbStylesToStylesheet";
import storage from "../../../utils/storage";
import { SESSION } from "../../../utils/session";
import { carrito } from "../signals/carrito";
import CartWrapper from "../Cart/CartWrapper";

const CartComp = ({ params, config }) => {
    const [myDesign, setMyDesign] = useState(null);
    const [myChannel, setMyChannel] = useState(null);
    const [myVariants, setMyVariants] = useState(null);
    const [myExtras, setMyExtras] = useState(null);

    useEffect(() => {
        Promise.all([
            api.shopByToken(params.roomshop),
            Promise.all(
                (carrito.value[params.roomshop] ?? []).map((x) => {
                    const cItem = carrito.value[params.roomshop].find(
                        (item) => item.variant === x.variant && item.timestamp === x.timestamp,
                    );
                    return api.variant(x.variant, params.roomshop, cItem.quantity, cItem.timestamp);
                }),
            ),
            api.design(config.landingPage.designID),
            Promise.all(
                (carrito.value[params.roomshop] || []).flatMap((x) =>
                    Object.keys(x.config).map((x) => api.extra(x, params.roomshop)),
                ),
            ),
        ])
            .then(([channel, variants, design, extras]) => {
                config.showLoadingContent(false);
                const variantRecord = variants.reduce((acc, cur) => {
                    acc[cur.id] = cur;
                    return acc;
                }, {});
                const translatedExtras = extras
                    .map((x) => ({
                        ...x,
                        name:
                            x.translations.find((x) => x.languageCode === storage.get("lang", "en"))?.name ||
                            x.translations[0].name,
                    }))
                    .reduce((acc, cur) => {
                        acc[cur.id] = cur;
                        return acc;
                    }, {});
                setMyDesign(design);
                setMyChannel(channel);
                setMyVariants(variantRecord);
                setMyExtras(translatedExtras);
            })
            .catch((e) => {
                console.error(e);
                onPromiseVendureError();
            });
    }, []);

    if (!myChannel && !myVariants) {
        config.showLoadingContent(true);
        return null;
    }

    return (
        <div id="sales">
            <CartWrapper
                channel={myChannel}
                templating={{
                    css: dbStylesToStylesheet(myDesign?.style),
                    texts: config.texts,
                    languageCode: storage.get("lang", "en"),
                    projectLanguageCode: config.projectLanguageCode,
                }}
                variants={myVariants}
                texts={config.texts}
                extras={myExtras}
            />
        </div>
    );
};

export default CartComp;
