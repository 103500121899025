import { SESSION } from "../../../utils/session";
import { Landing, Signage } from "../../../utils/signageUtils";
import Query from "../../query";

const CONTENTS_PART = `contents {duration id pos ref signageID type}`;
const SCHEDULE_PART = `scheduleStart scheduleEnd timeRanges{ endTime startTime} scheduleDaysOfWeek scheduleDaysType scheduleDailyType`;
const SEQUENCE_PART = `id name available contentLanguageRef isDefault ${CONTENTS_PART} ${SCHEDULE_PART}`;

export const getSignageSequences = (onSuccess?: (sequences: Signage[] | null, landing: Landing | null) => void) => {
    Query({
        query: `{
            signageContent{
                signages {${SEQUENCE_PART}} 
                designID
                md5
            }

            landingContent{
                designID
                contentStyle
            }
        }`,
        onResponse(res) {
            if (res?.data?.signageContent?.signages) {
                if (onSuccess) {
                    SESSION.currentSignage.md5 = res?.data?.signageContent?.md5;
                    SESSION.currentSignage.designId = res?.data?.signageContent?.designID;
                    onSuccess(res?.data?.signageContent?.signages, res?.data?.landingContent);
                    // onSuccess(MOCK_SIGNAGES);
                }
            } else if (res && (!res.data || (res.data && !res.data.signageContent))) {
                if (onSuccess) {
                    console.log("NO HAY RESPUESTA");
                    onSuccess(null, null);
                    // onSuccess(MOCK_SIGNAGES);
                }
            } else if (res?.error) {
                console.log("SOME ERRORS " + res?.error);
            }
        },
        cache: false,
        onError() {
            console.log("ERROR  onError");
        },
    });
    return null;
};
