import { Categories } from "sales-layout-library/tv";
import { salesContentEmpty } from "../../../utils/screenUtils";
import { eventHandler } from "../../../utils/eventHandler";
import { carrito } from "../signals/carrito";
import dbStylesToStylesheet from "../../../utils/dbStylesToStylesheet";
import storage from "../../../utils/storage";
import focus from "../../../utils/focus";
import { Link } from "wouter/preact";
import HorizontalList from "../../reusable/horizontalList";
import BackgroundImage from "../../reusable/backgroundImage";
import { useEffect, useState } from "preact/hooks";
import api from "../api";
import { navigate } from "wouter/use-location";
import salesNoContentComponent from "./salesNoContent";

const CategoriesComp = ({ params, config }) => {
    const [myChannel, setMyChannel] = useState(null);
    const [myDesign, setMyDesign] = useState(null);
    const [myCategories, setMyCategories] = useState(null);

    useEffect(() => {
        Promise.all([
            api.categories(params.roomshop),
            api.shopByToken(decodeURIComponent(params.roomshop)),
            api.design(config.landingPage.designID),
        ])
            .then(([categories, channel, design]) => {
                setMyChannel(channel);
                setMyDesign(design);
                setMyCategories(categories);
                config.showLoadingContent(false);
            })
            .catch((e) => {
                vendureCacheCleanup();
            });
    }, []);

    if (!myChannel && !myCategories) {
        config.showLoadingContent(true);
        return null;
    }

    if (salesContentEmpty(myChannel, myCategories, null)) {
        return salesNoContentComponent(myDesign?.style, "shop not availabe");
    }
    sessionStorage.setItem("roomshopId", myChannel?.id);
    if (myCategories?.length === 1) {
        eventHandler.skipNextPath(); //this should be added if replace is trye in navigate options
        navigate(`/roomshops/${params.roomshop}/categories/${myCategories[0].id}`, {
            replace: true,
        });
        return;
    }

    return (
        <div id="sales">
            <Categories
                data={{
                    channel: myChannel,
                    categories: myCategories,
                    cart: carrito.value[params.roomshop] ?? [],
                }}
                templating={{
                    css: dbStylesToStylesheet(myDesign?.style),
                    texts: config.texts,
                    languageCode: storage.get("lang", "en"),
                    projectLanguageCode: config.projectLanguageCode,
                }}
                signals={{ focus }}
                injected={{
                    Link,
                    List: HorizontalList,
                    BackgroundImage,
                }}
            />
        </div>
    );
};

export default CategoriesComp;
