import { signal, computed } from "@preact/signals";
import { eventHandler } from "./eventHandler";

const _focus = signal<string[][]>([]);

const MAX_UNDO_STEPS = 50;
type FocusOptions = { preventScroll?: boolean } | undefined;
//@ts-expect-error
let lastOpts: FocusOptions;
let lastFocusedElement: HTMLElement | null;
const applyFocusStyles = (element: HTMLElement) => {
    eventHandler.setFocusedBtn(element);
    if (!element.classList.contains("navigable")) {
        // TODO debería comprobarse antes de cambiar el foco
        return;
    }
    if (element.getAttribute("focusclass")) {
        element.classList.add(element.getAttribute("focusclass") || "");
    } else if (element.getAttribute("menuItem") || element.getAttribute("itemid")) {
        // elem.classList.add("focusFgColor");
        // if (elem.getAttribute("focusOutline")) {
        //     elem.classList.add("menuOutline");
        // } else {
        //     elem.classList.add("menuBtnFocused");
        // }
    } else if (element.classList.contains("btn-focusBorder")) {
        element.classList.add("focusBorderColor");
    } else if (element.classList.contains("btn-focusOutline")) {
        element.classList.add("focusOutline");
    } else {
        element.classList.add("focusFgColor");
        element.classList.add("btnFocused");
    }

    element.setAttribute("tabIndex", "0");
    // prevent-scroll
    const scroll = element.getAttribute("prevent-scroll") === "true" ? true : false;
    element.focus({ preventScroll: scroll });
};

const removeFocusStyles = (element: HTMLElement) => {
    if (element.getAttribute("focusclass")) {
        element.classList.remove(element.getAttribute("focusclass") || "");
    }
    element.classList.remove("btnFocused");
    element.classList.remove("focusOutline");
    element.classList.remove("focusBorderColor");
    element.classList.remove("focusFgColor");
    element.classList.remove("menuBtnFocused");
    element.classList.remove("menuBtnActive");
    element.classList.remove("menuOutline");
};

const focus = computed(() => {
    const getLastEntryWithValue = (cFocus: string[][]): string[][] => {
        return cFocus.length > 1
            ? !cFocus.slice(0, -1)?.at(-1)?.at(-1)
                ? getLastEntryWithValue(cFocus.slice(0, -1))
                : cFocus.slice(0, -1)
            : cFocus;
    };

    return {
        current: _focus.value?.at(-1)?.at(-1),
        unstack() {
            _focus.value = [...getLastEntryWithValue(_focus.value)];
        },
        stack(id: string) {
            _focus.value.push(id ? [id] : []);
            _focus.value = [..._focus.value];
        },
        add(id: string, opts?: FocusOptions) {
            lastOpts = opts;
            if (!id) return;
            const newFocus = !Array.isArray(_focus.value.at(-1)) ? [id] : _focus.value.at(-1)!.concat(id);
            if (newFocus.length > MAX_UNDO_STEPS) newFocus.shift();
            _focus.value = [..._focus.value, newFocus];
        },
        replace(id: string, opts?: FocusOptions) {
            lastOpts = opts;
            const lastFocusStack = _focus.value.at(-1);
            if (lastFocusStack) {
                if (lastFocusStack.length > 0) {
                    lastFocusStack.pop();
                }
                lastFocusStack.push(id);
                _focus.value = [..._focus.value];
            } else {
                this.add(id);
            }
        },
        back() {
            _focus.value.at(-1)?.pop();
            _focus.value = [..._focus.value];
        },
        reset() {
            _focus.value = [];
        },
    };
});

focus.subscribe((value) => {
    const currentFocusedElement = document.getElementById(value.current ?? "limbo");
    if (currentFocusedElement === lastFocusedElement) {
        // Do not render if it is not necessary
        return;
    }
    if (lastFocusedElement) removeFocusStyles(lastFocusedElement);
    if (currentFocusedElement) applyFocusStyles(currentFocusedElement);
    lastFocusedElement = currentFocusedElement;
});

export default focus;
