import {
    SHOW_MENU,
    SHOW_CZ_MENU,
    SHOW_LOADING,
    SET_POPUP,
    POP_LAYOUT_HISTORY,
    SET_TEXTS,
    SET_VIDEO_POS,
    NAVIGATE_TO,
    SET_LANDING,
    SET_BACKGROUND,
    FORCE_BACKGROUND_RESTORE,
    SET_LANDING_BACKGROUND,
    SET_MENU_LAST_FOCUS,
    SET_MENU_TEMPORARY_TYPE,
    SET_FLAG_WIGETS_POS,
    SET_POPUP_ERROR,
    SET_APP_VISIBILITY,
    RENEW_SIGNAGE,
} from "../actions/uiActions";
import { Media } from "../hooks/apis/media";
import { MENU_CONTAINER, CONTAINER_WIDGET, MENU_TYPES } from "../utils/constants";
import focus from "../utils/focus";
import { parseMenuColorsAndAnimation } from "../utils/screenUtils";
import { Theme } from "../utils/theme";
import { completeDasURL, isMenuItem } from "../utils/utils";

const supportedLang = ["en", "es"];
const currentLang =
    localStorage.getItem("lang") && supportedLang.indexOf(localStorage.getItem("lang")) > -1
        ? localStorage.getItem("lang")
        : "en";

const initialState = {
    showMenu: false,
    renewSignage: false,
    landingPage: null,
    navigateTo: null,
    popup: null,
    loading: true,
    texts: typeof localStorage !== "undefined" ? require(`../lang/${currentLang}/general.json`) : {},
    isVideoFullScreen: false,
    flagGetWidgetPos: true,
    blockedKeys: [],
    popUpError: { text: null, timeout: 5000 },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SHOW_MENU:
            if (state.menu && state.menu.type === MENU_TYPES.NONE) {
                return { ...state };
            }
            return {
                ...state,
                showMenu: action.payload,
            };
        case SHOW_LOADING:
            return {
                ...state,
                loading: action.payload,
            };

        case SET_POPUP:
            //si estamos fuera de la interface no mostramos ningún popup
            //para evitar perder control del home
            if (sessionStorage.getItem("outOfInterface") === "true") {
                return {
                    ...state,
                };
            }
            if (state.popup && !action.payload) {
                focus.value.unstack();
                if (sessionStorage.getItem("forceFocus")) {
                    focus.value.replace(sessionStorage.getItem("forceFocus"));
                    sessionStorage.removeItem("forceFocus");
                }
            } else if (!state.popup && action.payload) {
                if (
                    state.menu &&
                    (state.menu.type === MENU_TYPES.HIDDEN || state.menu?.temporaryType === MENU_TYPES.HIDDEN)
                ) {
                    if (state.showMenu) {
                        focus.value.unstack();
                    }
                }
                focus.value.stack();
            }

            return {
                ...state,
                popup: action.payload,
            };

        case POP_LAYOUT_HISTORY:
            // eslint-disable-next-line no-case-declarations
            let copy = JSON.parse(JSON.stringify(state.layoutHistory));

            copy.shift();

            return {
                ...state,
                layoutHistory: copy,
            };

        case SET_TEXTS:
            // eslint-disable-next-line no-case-declarations
            let iso = "en";
            if (action.payload === "es") {
                iso = "es";
            }

            return {
                ...state,
                texts: require(`../lang/${iso}/general.json`),
            };

        case SET_VIDEO_POS: {
            // eslint-disable-next-line no-var
            let isFullScreen = false;
            if (action.payload) {
                if (action.payload === "fullscreen") {
                    action.payload = {
                        x: 0,
                        y: 0,
                        width: 100,
                        height: 100,
                    };
                }
                Media.lastVideoPos = {
                    x: action.payload.x,
                    y: action.payload.y,
                    width: action.payload.width,
                    height: action.payload.height,
                };
                Media.setVideoSize(action.payload.x, action.payload.y, action.payload.width, action.payload.height);
                if (
                    action.payload.x === 0 &&
                    action.payload.y === 0 &&
                    action.payload.width === 100 &&
                    action.payload.height === 100
                ) {
                    isFullScreen = true;
                }
            } else {
                Media.setVideoSize(0, 0, 0, 0);
            }
            Media.isVideoFullScreen = isFullScreen;

            return {
                ...state,
                videoPos: action.payload,
                isVideoFullScreen: isFullScreen,
            };
        }

        case SET_BACKGROUND:
            return {
                ...state,
                background: action.payload,
            };
        case FORCE_BACKGROUND_RESTORE:
            return {
                ...state,
                restoreBg: action.payload,
            };
        case SET_LANDING_BACKGROUND:
            if (action.payload) {
                return {
                    ...state,
                    landingBg: action.payload,
                };
            }
            return {
                ...state,
                background: state.landingBg,
            };

        case NAVIGATE_TO:
            if (window.location.pathname !== action.payload?.layout) {
                focus.value.stack();
            }

            return {
                ...state,
                navigateTo: action.payload,
            };
        case SET_LANDING:
            if (action.payload?.noLanding) {
                return {
                    ...state,
                    landingPage: action.payload,
                };
            }
            try {
                let _widget_menu = null;
                let _landing_widgets_no_menu = [];
                if (action.payload.contentWidgets.length > 0) {
                    for (let index = 0; index < action.payload.contentWidgets.length; index++) {
                        const element = action.payload.contentWidgets[index];
                        if (element.type === MENU_CONTAINER) {
                            const menuData = JSON.parse(element.data);
                            _widget_menu = menuData.menuType === MENU_TYPES.NONE ? null : element;
                        } else {
                            _landing_widgets_no_menu.push(element);
                        }
                    }
                }
                //Get menu pos
                const landingPos = action?.payload?.pos ? JSON.parse(action.payload.pos) : null;
                let menuPos = null;
                let pos_no_menu = { TV: [] };
                if (landingPos && _widget_menu) {
                    menuPos = landingPos.TV.filter((w) => w.ID === _widget_menu.ID)[0];
                }
                if (menuPos) {
                    for (let index = 0; index < landingPos.TV.length; index++) {
                        const elementPos = landingPos.TV[index];
                        if (_widget_menu && elementPos.ID !== _widget_menu.ID) {
                            pos_no_menu.TV.push(elementPos);
                        }
                    }
                } else {
                    pos_no_menu = landingPos;
                }
                // create LANDING SCREEN WITHOUT MENU
                let landingScreen = {
                    contentStyle: action.payload.contentStyle,
                    contentWidgets: _landing_widgets_no_menu,
                    designID: action.payload.designID,
                    devices: action.payload.devices,
                    id: action.payload.id,
                    name: action.payload.name,
                    pos: JSON.stringify(pos_no_menu),
                };
                // create MENU
                if (_widget_menu) {
                    const _menu_data = JSON.parse(_widget_menu.data);
                    const _menu_style = JSON.parse(_widget_menu.widgetStyle);
                    state.menu = {
                        blurMainScreen: _menu_data.menuType === MENU_TYPES.HIDDEN ? _menu_data.blurMainScreen : false,
                        menuKeys: _menu_data.buttonRC,
                        type: _menu_data.menuType,
                        pos: menuPos,
                        animation: getMenuAnimation(menuPos, action.payload.devices),
                        content: {
                            ..._widget_menu,
                            type: CONTAINER_WIDGET,
                        },
                    };
                    state.showMenu = _menu_data.menuType !== MENU_TYPES.NONE;
                    // for widgetsRender
                    state.menu.menuColorsAndAnimations = parseMenuColorsAndAnimation(_menu_style, _menu_data);
                    Theme.addMenuStyles(state.menu.menuColorsAndAnimations);
                } else {
                    state.menu = { type: MENU_TYPES.NONE };
                    state.showMenu = false;
                }

                const lStyle = JSON.parse(action.payload.contentStyle);
                let imageUrl = null;
                if (lStyle?.images?.[0]?.libraryRef) {
                    imageUrl = completeDasURL(lStyle.images[0].libraryRef, { width: "full" });
                } else if (lStyle?.images?.[0]?.externalUrl) {
                    imageUrl = lStyle.images[0].externalUrl;
                }
                state.landingBg = {
                    image: imageUrl,
                    bgColor: lStyle?.bgColor ? lStyle.bgColor : null,
                    opacity: lStyle?.opacity ? lStyle.opacity / 100 : 100,
                };
                // filter TVMENU from landing to prevent render in screen
                return {
                    ...state,
                    landingPage: landingScreen,
                };
            } catch (error) {
                console.log(error);
            }
        // eslint-disable-next-line no-fallthrough
        case SET_MENU_LAST_FOCUS:
            // eslint-disable-next-line no-case-declarations
            let menu = state.menu;
            if (isMenuItem(action.payload)) {
                menu.lastFocus = action.payload;
            }
            return { ...state, menu };
        case SET_MENU_TEMPORARY_TYPE:
            // eslint-disable-next-line no-case-declarations
            let _menu = state.menu;
            if (_menu) {
                _menu.temporaryType = action.payload;
            }

            return { ...state, menu: _menu };
        case SET_FLAG_WIGETS_POS:
            return { ...state, flagGetWidgetPos: action.payload };
        case SET_POPUP_ERROR:
            return { ...state, popUpError: action.payload };
        case SET_APP_VISIBILITY:
            return { ...state, appVisibility: action.payload };
        case RENEW_SIGNAGE:
            return { ...state, renewSignage: action.payload };
        default:
            return state;
    }
}

//return menu animation fadeInAnimation , topAnimation, bottomAnimation, leftAnimation, rightAnimation
const getMenuAnimation = (menuPos, devices) => {
    if (!menuPos || !devices) {
        return "fadeInAnimation";
    }
    const tvGrid = devices.filter((d) => d.type === "TV")[0].grid;
    const SIZE_RANGE = 5;

    if (menuPos.X === 0 && menuPos.Y === 0) {
        if (menuPos.H >= tvGrid.rows && menuPos.W >= tvGrid.cols - SIZE_RANGE) {
            return "fadeInAnimation";
        }
        if (menuPos.H >= tvGrid.rows - SIZE_RANGE) {
            return "leftAnimation";
        }
        return "topAnimation";
    } else if (menuPos.X === 0 && menuPos.Y + menuPos.H >= tvGrid.rows - SIZE_RANGE) {
        return "bottomAnimation";
    } else if (menuPos.X + menuPos.W >= tvGrid.cols - SIZE_RANGE) {
        return "rightAnimation";
    } else if (menuPos.X === 0) {
        return "leftAnimation";
    } else if (menuPos.Y === 0) {
        return "topAnimation";
    }
    return "fadeInAnimation";
};
