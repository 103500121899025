// widgets
export const MENU_CONTAINER = "TVMENU" as const;
export const CONTAINER_WIDGET = "CONTAINER" as const;
export const MENUNAV_WIDGET = "TVMENUNAV" as const;
export const ALARM_WIDGET = "ALARM" as const;
export const MESSAGES_WIDGET = "MESSAGES" as const;
export const LANGSELECTION_WIDGET = "LANGSELECTION" as const;
export const SLIDER_WIDGET = "SLIDER" as const;
export const CAROUSEL_WIDGET = "CAROUSEL" as const;
export const VIDEO_WIDGET = "VIDEO" as const;
export const CLOCK_WIDGET = "CLOCK" as const;
export const BUTTON_WIDGET = "BUTTON" as const;
export const MENU_TYPES = { NONE: "none", FIXED: "fixed-menu", HIDDEN: "hidden-menu" } as const;
export const NO_RENDERED_WIDGETS = ["TOPBAR", "CHATBUTTON", "ROOMS", MENU_CONTAINER] as const;
export const EXTRAINFO_WIDGETS = [
    VIDEO_WIDGET,
    ALARM_WIDGET,
    CLOCK_WIDGET,
    MESSAGES_WIDGET,
    BUTTON_WIDGET,
    SLIDER_WIDGET,
] as const;
export const FOCUS_WIDGET_WITH_NO_ACTION = [
    ALARM_WIDGET,
    MESSAGES_WIDGET,
    MENUNAV_WIDGET,
    VIDEO_WIDGET,
    LANGSELECTION_WIDGET,
    SLIDER_WIDGET,
] as const;
export const PREVENT_FOCUS_OUTLINE = [CAROUSEL_WIDGET, SLIDER_WIDGET];
// sceens
export const HOME = "HOME" as const;
export const SCREEN = "screen" as const;
export const CCINSTRUCTIONS_SCREEN = "ccinstruction-screen" as const;
export const SIGNAGE = "signage";
export const DEFAULT = "default";

export const ANIMATION_TIMEOUT = 400; //ms

export const NO_MENU_SCREENS = [
    "tv",
    "ccinstruction-screen",
    "checkoutsuccess",
    "chromecast",
    "library",
    "tvchannel",
    "roomshops",
    "movies",
    "adult-movies",
    "movie",
] as const;
export const MESSAGES_STYLES = { OVERLAY: "overlay" } as const;

export const POP_UP = {
    MESSAGES: "messages",
    ALARM: "alarm",
    BILL: "bill",
    CHECKOUT: "checkout",
    LANGSELECTION: "languageSelection",
    PARENTALCODE: "parentalcode",
    RESETCREDENTIALS: "resetCredentials",
    INPUTS: "inputs",
    //@ts-ignore
    list() {
        let _valuesList = [];
        for (const element of Object.keys(this)) {
            const iterator = element as keyof typeof POP_UP;
            _valuesList.push(this[iterator] as string);
        }
        return _valuesList;
    },
} as const;

export const ZAFIRO_MODES = {
    FULL_INTERFACE: "FULL_INTERFACE",
    TV: "TV",
    COMMON_ZONE: "COMMON_ZONE",
};

export const WIDGETS = {
    VIDEO: "VIDEO",
};
export const PATHS = {
    NOLANDING: "/noLanding",
    TV: "/tv",
    TVCHANNEL: "/tvchannel/:channelId",
    CCINSTRUCTIONS: "/ccinstruction-screen",
    CHECKOUTSUCCESS: "/checkoutsuccess",
    LIBRARY: "/library",
    ROOMSHOPS: "/roomshops",
    ROOMSHOP: "/roomshop/:roomshop",
    CATEGORIES: "/roomshops/:roomshop/categories",
    SUBCATEGORIES: "/roomshops/:roomshop/categories/:category",
    SUBCATEGORY: "/roomshops/:roomshop/categories/:category/subcategory/:subcategory",
    PRODUCT: "/roomshops/:roomshop/products/:productId/variant/:variantId/:timestamp?",
    CART: "/roomshops/:roomshop/cart",
    SCREEN: "/screen/:rest*",
    ROOT: "/",
    HOME: "/",
    TIZEN_ROOT: "/index.html",
    SCREENS: "/screen/",
    MYORDERS: "/myorders",
    MYORDERDETAIL: "/myorders/:order",
    MOVIES: "/movies",
    HOLLYWOOD_MOVIES: "/hollywood-movies",
    ADULT_MOVIES: "/adult-movies",
    MOVIE_PLAYER: "/movie/:id/play",
    MOVIE_TRAILER: "/movie/:id/trailer",
} as const;

export const PATHS_WITH_NO_MENU = {
    list: [
        PATHS.TV,
        PATHS.ROOMSHOPS,
        PATHS.LIBRARY,
        PATHS.CCINSTRUCTIONS,
        PATHS.CHECKOUTSUCCESS,
        PATHS.MYORDERS,
        PATHS.MOVIES,
        PATHS.HOLLYWOOD_MOVIES,
        PATHS.ADULT_MOVIES,
        PATHS.MOVIE_PLAYER,
        PATHS.MOVIE_TRAILER,
    ],
    pathIncludes: (path: string) => {
        if (!path || typeof path === "undefined") {
            return false;
        }
        let _result = false;
        for (const element of PATHS_WITH_NO_MENU.list) {
            const iterator = element as keyof typeof PATHS_WITH_NO_MENU;
            if (iterator.indexOf(path) > -1) {
                _result = true;
            }
        }
        return _result;
    },
} as const;

export const CHECK_ONLINE_PATH = "https://drive.zafiro.cloud/favicon.ico";
export const TVGW_PATH = "http://tvgw.local";

export const DEFAULT_ACTIVE_MENU_HISTORY = [{ menuFocus: null, path: "/" }];

export const TIME = {
    ONE_HOUR_IN_MS: 3_600_000,
    ONE_MIN_IN_MS: 60000,
    ONE_DAY_IN_MS: 86400000,
} as const;

export const CHANNELS = {
    FILTERS: {
        ALL_CHANNELS: "all_channels",
        ALL: "all",
        MY_LIST: "my_list",
        COUNTRY: "country",
        LANGUAGE: "language",
    },
    TV_STATES: {
        LIMBO: "limbo",
        CHANNEL_LIST: "channel_list",
        CHANNEL_INFO: "channel_info",
        CHANNEL_DISPLAY: "channel_display",
    },
};
export const MOVIES = {
    RENTHOURS: 24,
    FILTERS: {
        ALL: "ALL",
        ALL_MOVIES: "all_movies",
        COUNTRY: "country",
        LANGUAGE: "language",
        RENTED: "rented",
    },
};
export const TV_MODELS = {
    TIZEN: "Samsung-Tizen",
    ORSAY: "Samsung-Orsay",
    LG: "LG",
    PHILIPS: "Philips",
    PHILIPS_NO_CC: "Philips-NoCC",
    GOOGLE_TV: "GoogleTV",
    STB: "STB",
    PC: "PC",
};
export const DEVICE_FUNCTION_LIST = {
    TV: "TV",
    TVCAST: "TV+CAST",
    CAST: "CAST",
};
export const DEVICE_FUNCTION = {
    TV: {
        id: "TV",
        name: "TV",
    },
    TVCAST: {
        id: "TVCAST",
        name: "TV + CAST",
    },
};
export const PMS = {};

// 25-04-2024
// Available list of permissions coming from back: [
//     highAvailability,
//     newChannels
// ]
export const PERMISSION = {
    HIGH_AVAILABILITY: "highAvailability",
    PRODUCT_CHANNELS: "productCHANNELS",
    PRODUCT_CAST: "productCAST",
    PRODUCT_SIGNAGE: "productSIGNAGE",
    NEW_MOVIES: "newMovies",
    APPS: "apps",
    FREE_TO_GUEST: "freeToguest",
    ADULT_CONTENT: "adultContent",
};

export const STATE = {
    ONLINE: "online",
    OFFLINE: "offline",
    STANDBY: "standby",
    NORMAL: "normal",
};

export const STORAGE_KEY = {
    POWER_STATE: "powerState",
    RESET_CREDENTIALS: "resetCredentialsOnPowerOff",
    ALL_GRID_ITEMS: "ALL_GRID_ITEMS",
    SHOW_GROUP: "groupSelected",
};

export const POWER = {
    ON: "ON",
    OFF: "OFF",
};

export const VISIBILITY = {
    VISIBLE: "VISIBLE",
    HIDDEN: "HIDDEN",
};

export const GENDER = {
    MALE: "male",
    FEMALE: "female",
};

export const MOVIE_DETAIL_DISPLAY = {
    TRAILER: "trailer",
    PLAYER: "player",
    MORE_PLOT: "morePlot",
    DETAIL: "detail",
};

export const PLAY_STATUS = {
    playing: "PLAYING",
    pause: "PAUSE",
    stop: "STOP",
};
export const LOADING_STYLE = {
    color: "white",
    fontSize: "1.87305vw",
    textShadow: "1px 1px black",
    zIndex: 100,
};

export const ROOMTV_TYPE = {
    COMMONZONE: "COMMONZONE",
    TV: "GUESTROOM",
};

export const MS_INTERVAL = {
    ONE_SECOND: 1000,
    ONE_MINUTE: 60000,
};
