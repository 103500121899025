import { useSelector } from "react-redux";
import NoContent from "../../NoContent";

const salesNoContentComponent = (style, keytext) => {
    const texts = useSelector((state) => state.ui.texts);
    return (
        <NoContent
            text={texts[keytext]}
            style={{
                color: style?.section3?.colors?.fgColor,
                backgroundColor: style?.section3?.colors?.bgColor,
            }}
        />
    );
};

export default salesNoContentComponent;
