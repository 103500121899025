import { Theme } from "./theme";
import { completeDasURL, composeNavigationPath, replaceAll } from "./utils";
import { Texts } from "./texts";
import {
    NO_RENDERED_WIDGETS,
    MENUNAV_WIDGET,
    MESSAGES_WIDGET,
    ALARM_WIDGET,
    POP_UP,
    MENU_TYPES,
    FOCUS_WIDGET_WITH_NO_ACTION,
    CONTAINER_WIDGET,
    HOME,
    PATHS,
} from "./constants";
import { createElement } from "preact/compat";
import { setPopUp, displayMenu, setMenuLastFocus, navigateTo } from "../actions/uiActions";
import { useDispatch, useSelector } from "react-redux";
import { setActiveMenuBtnID, setActiveMenuToRestart } from "../actions/eventsActions";
import focus from "./focus";
import { eventHandler } from "./eventHandler";
import { closestElement } from "./movement";
import { KEYS } from "./keys";
import { STB } from "../hooks/apis/stb";

export const screenUtils = {
    contentStyle: null,
    contentWidgets: [],
    fonts: [],
    widgetsPos: null,
    grid: null,
    itemsMargin: null,
    colWidth: null,
    rowHeight: null,

    init: function (data, menuPos, isCCIntructions) {
        this.screenName = data?.name;
        this.isCCIntructions = isCCIntructions;
        this.contentStyle = JSON.parse(data.contentStyle || null);
        this.contentWidgets = data.contentWidgets || [];
        this.widgetsPos = this.compouseWidgetPos(data, menuPos);
        this.grid = data.devices.filter((device) => device.type === "TV")[0].grid;
        this.itemsMargin = this.contentStyle.itemsMargin;

        this.colWidth =
            (document.body.offsetWidth -
                (this.contentStyle.margin.w || 0) * 2 -
                (this.grid.cols - 1) * this.itemsMargin.w) /
            this.grid.cols;

        this.rowHeight =
            (document.body.offsetHeight -
                (this.contentStyle.margin.h || 0) * 2 -
                (this.grid.rows - 1) * this.itemsMargin.h) /
            this.grid.rows;
    },
    // eslint-disable-next-line
    getoffSetNavigableWidgets: function () {
        this.widgetsPos?.TV?.map(
            (element) =>
                (element.boundingClient = document.getElementById(window.ScreenRender.divPrefix + element.ID)
                    ? document.getElementById(window.ScreenRender.divPrefix + element.ID).getBoundingClientRect()
                    : null),
        );
        if (window.MenuRender && (!window.MenuRender.menuWidgetsPos || window.MenuRender.menuWidgetsPos.length === 0)) {
            window.MenuRender.menuWidgetsPos = [];
            let navigables = document.querySelectorAll(".navigable");
            for (let index = 0; index < navigables.length; index++) {
                const element = navigables[index];
                let menuItme = element.getAttribute("menuitem");
                if (menuItme) {
                    window.MenuRender.menuWidgetsPos.push({
                        ID: element.getAttribute("widgetId"),
                        boundingClient: element.getBoundingClientRect(),
                        navigable: true,
                        menuitem: true,
                        menuNavItem: element.getAttribute("menuNavItem") === "true",
                    });
                }
            }
        }
    },

    compouseWidgetPos(data, menuPos) {
        if (!data.pos && !menuPos) {
            return null;
        }
        //add screen navidagle widgets
        const _widgetpos = JSON.parse(data.pos);
        (_widgetpos.TV || []).map((_widget) => {
            _widget.navigable = screenUtils.isWidgetNavigable(_widget.ID, data);
        });
        //add widgets inside container if some is navigable
        const containerPos = screenUtils.addContainerNavigalbeWidgets(data);
        if (containerPos && containerPos.length > 0) {
            containerPos.forEach((element) => {
                _widgetpos.TV.push({ ...element, navigable: true });
            });
        }
        //return just navigable items
        return _widgetpos;
    },

    isWidgetNavigable(widgetID, data) {
        const widgetData = data.contentWidgets?.filter((widget) => widget.ID === widgetID)[0];
        if (!widgetData) {
            return false;
        }
        if (!this.isCCIntructions && FOCUS_WIDGET_WITH_NO_ACTION.indexOf(widgetData.type) > -1) {
            return true;
        }
        const widgetActions = screenUtils.parseWidgetActions(widgetData.actions);
        return !!(widgetActions.actions.length > 0 && widgetActions?.actions?.[0]?.value);
    },

    addContainerNavigalbeWidgets(data) {
        let _containerPos = [];
        data.contentWidgets.forEach((contentWidget) => {
            const widgetActions = screenUtils.parseWidgetActions(contentWidget.actions);
            if (contentWidget.type === CONTAINER_WIDGET && widgetActions.actions.length === 0) {
                const widgetContainerPos = JSON.parse(contentWidget.pos) ? JSON.parse(contentWidget.pos) : [];
                widgetContainerPos.forEach((wCpos) => {
                    if (screenUtils.isWidgetNavigable(wCpos.ID, contentWidget)) {
                        _containerPos.push(wCpos);
                    }
                });
            }
        });
        return _containerPos;
    },

    parseWidgetActions(actions) {
        return JSON.parse(actions) ? JSON.parse(actions) : { actions: [] };
    },

    getContentStyle() {
        if (!this.contentStyle) {
            return null;
        }
        return {
            background: "transparent",
            paddingTop: `${this.contentStyle.margin.h || 0}px`,
            paddingBottom: `${this.contentStyle.margin.h || 0}px`,
            paddingLeft: `${this.contentStyle.margin.w || 0}px`,
            paddingRight: `${this.contentStyle.margin.w || 0}px`,
            zIndex: 2,
        };
    },

    getContentAudioURL: function () {
        return this?.contentStyle?.music?.externalUrl ? this.contentStyle.music.externalUrl : null;
    },

    replaceSpecialTags(text, tags) {
        if (!text || !tags) return text;

        tags.forEach((tag) => {
            text = replaceAll(text, `{{.${tag.tag}}}`, tag.value || "");
            text = replaceAll(text, `{{.${Texts.capitalize(tag.tag)}}}`, tag.value || "");
        });

        return text;
    },

    getImageAdjust(adjust) {
        if (!adjust) {
            return "bg-cover";
        }
        switch (adjust) {
            case "cover":
                return "bg-cover";
            case "contain":
                return "bg-contain";
            case "cover-adjust":
                return "bg-cover-adjust";

            default:
                return "bg-cover";
        }
    },
    includeFonts() {
        this.contentWidgets.forEach((content) => {
            if (content.fonts) {
                this.importFonts(content.fonts);
            }
            //import fonts of container widgets
            if (content.contentWidgets) {
                content.contentWidgets.map((content) => {
                    if (content.fonts) {
                        this.importFonts(content.fonts);
                    }
                });
            }
        });
        return this.fonts;
    },

    importFonts: function (fonts) {
        fonts.map((font) => {
            this.fonts.push(font.name);
            const _libraryRef = font.libraryRef ? `${font.libraryRef}/?format=css` : ``;
            Theme.includeFonts(font.externalUrl || completeDasURL(`${_libraryRef}`));
        });
    },

    hasTVPos(widget) {
        return this._getWidgetPos(widget.ID);
    },
    hasContainerTVPos(widget, container) {
        return this._getContainerWidgetPos(widget.ID, container);
    },

    _getWidgetStyleProperty(widget, property) {
        const widgetStyle = JSON.parse(widget.widgetStyle);

        if (widgetStyle?.TV?.[property]) {
            return widgetStyle.TV[property];
        }
        if (widgetStyle?.[property]) {
            return widgetStyle[property];
        }
        return "";
    },
    _getWidgetPos: function (widgetId) {
        const elemToIterate = this?.widgetsPos?.TV ? this.widgetsPos.TV : this.widgetsPos;

        return elemToIterate?.filter((widget) => widget.ID === widgetId)
            ? elemToIterate.filter((widget) => widget.ID === widgetId)[0]
            : null;
    },
    _getContainerWidgetPos(widgetId, container) {
        const pos = JSON.parse(container.pos);
        return pos.filter((widget) => widget.ID === widgetId)
            ? pos.filter((widget) => widget.ID === widgetId)[0]
            : null;
    },

    getBgURL: function (contentStyle) {
        if (!contentStyle?.images?.[0]) {
            return null;
        }
        return `${
            contentStyle?.images?.[0]
                ? contentStyle.images[0].externalUrl ||
                  completeDasURL(contentStyle.images[0].libraryRef, { width: "full" })
                : ""
        }`;
    },
};

/** 
* @params { widgetItems: Array widgets rendered, 
            widget: data widget to render, 
            container: container of widget if any, 
            unreadMessages, 
            alarm, 
            videoPresent, 
            setVideoPresent, 
            useWidgetRender: Render library 
        }
 **/
export const printMenuWidget = (params) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { widgetItems, widget, container, unreadMessages, alarm, useWidgetRender } = params;
    const dispatch = useDispatch();
    if (widget && NO_RENDERED_WIDGETS.indexOf(widget.type) > -1) {
        return;
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { activeMenuBtnId, focusToRestart } = useSelector((state) => state.events);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const menu = useSelector((state) => state.ui.menu);
    let action = getWidgetAction(widget);
    if (action && (menu.type === MENU_TYPES.HIDDEN || menu.temporaryType === MENU_TYPES.HIDDEN)) {
        action.hideMenu = true;
    }
    let widgetStatus = "defaultColor";
    if (useWidgetRender.divPrefix + widget.ID === activeMenuBtnId) {
        widgetStatus = "activeColor";
    } else if (useWidgetRender.divPrefix + widget.ID === focus.value.current) {
        widgetStatus = "focusColor";
    }
    let widgetRendered = useWidgetRender.widget(widget, container, {
        unreadMessages,
        alarm: {
            configured: !!alarm,
            date: alarm ? new Date(alarm.startTime) : null,
        },
        insideMenu: true,
        statusStyle: widgetStatus,
        focusedBtnId: focus.value.current,
        activeBtnId: activeMenuBtnId,
    });
    widgetRendered.tabIndex = 0;
    let classes = [
        widgetRendered.style.border ? "btn-focusBorder" : "btn-focusOutline",
        isNavigable(widget) && action ? "navigable" : null,
    ];
    if (["LOGO", "IMAGE"].indexOf(widget.type) > -1) {
        widgetRendered.setAttribute("focusOutline", true);
        if (focus.value.current?.indexOf(widget.ID) > -1) {
            classes.push("menuOutline");
        }
    }

    //Add TV-app widget classes and styles to the rendered widget
    widgetRendered.className = classes.join(" ");

    //Get rendered widget
    const widgetDiv = createElement("div", {
        onClick: (e) => {
            const parentNavigable = closestElement(e.target, "navigable");
            if (e.detail === 1) {
                //only for mouse events
                action = getWidgetAction(widget, parentNavigable?.getAttribute("menunavitempos"));
            }

            onclickEventForAction(action, dispatch, {
                focusedBtnId: focus.value.current,
                activeMenuBtnId,
                focusToRestart: menu.type === MENU_TYPES.HIDDEN ? focusToRestart : null,
            });
        },
        dangerouslySetInnerHTML: {
            __html: widgetRendered.outerHTML,
        },
    });

    if (typeof widgetRendered === "object") {
        widgetItems.push(widgetDiv);
    }
};

const onclickEventForAction = (action, dispatcher, btnsId) => {
    let actionIsPopup = false;
    if (action && POP_UP.list().indexOf(action.layout) > -1) {
        actionIsPopup = true;
    }

    //if its a tvmenunav item and action a pop up save last menuActive to restore when closing pop up
    if (actionIsPopup && btnsId.activeMenuBtnId && btnsId.activeMenuBtnId !== btnsId.focusedBtnId) {
        dispatcher(setActiveMenuToRestart(btnsId.activeMenuBtnId));
    }
    // save offSet of tvmenunav
    if (!window.MenuRender.activeMenuOffSet) {
        window.MenuRender.activeMenuOffSet = {};
    }
    window.MenuRender.activeMenuOffSet[document.getElementById(btnsId.focusedBtnId)?.parentNode?.id] =
        window.MenuRender?.tvmenunavOffSetToApply?.[document.getElementById(btnsId.focusedBtnId)?.parentNode?.id];

    //set current focused item to activeMenu
    dispatcher(setActiveMenuBtnID(btnsId.focusedBtnId));
    dispatcher(setMenuLastFocus(btnsId.focusedBtnId));
    if (action?.hideMenu) {
        eventHandler.preventMenuUnstack = true;
        dispatcher(displayMenu(false));
        focus.value.unstack();
    }
    // hide menu if is in layout
    if (action) {
        switch (action.layout) {
            case POP_UP.MESSAGES:
            case POP_UP.ALARM:
            case POP_UP.BILL:
            case POP_UP.CHECKOUT:
            case POP_UP.PARENTALCODE:
            case POP_UP.RESETCREDENTIALS:
            case POP_UP.INPUTS:
                dispatcher(setPopUp(action.layout));
                break;
            case POP_UP.LANGSELECTION:
                dispatcher(setPopUp("language"));
                break;
            case HOME:
                dispatcher(
                    navigateTo({
                        layout: PATHS.ROOT,
                    }),
                );
                break;
            case "tvchannel":
                localStorage.setItem("channel_to_restore", action.id);
                //document.body.style.visibility = "hidden";
                dispatcher(
                    navigateTo({
                        layout: PATHS.TV,
                    }),
                );
                break;
            case "netflix":
            case "appletv":
            case "youtube":
                STB.launchApp(action.layout.toUpperCase());
                break;
            default:
                action.prevFocusId = btnsId.focusToRestart ? btnsId.focusToRestart : btnsId.focusedBtnId;
                action.menuActiveBtnId = btnsId.focusedBtnId;
                const _actionId = action?.id ? `/${action.id}` : "";
                dispatcher(
                    navigateTo({
                        layout: composeNavigationPath(action),
                    }),
                );
        }
    }
};

const getWidgetAction = (widget, pos) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // eslint-disable-next-line react-hooks/rules-of-hooks
    let action = {};
    //Special widgets
    if (widget.type === MESSAGES_WIDGET) {
        action.layout = "messages";
        return action;
    }
    if (widget.type === ALARM_WIDGET) {
        action.layout = "alarm";
        return action;
    }

    if (!widget.actions) return null;
    let actions = JSON.parse(widget.actions);
    if (!actions) return null;
    actions = actions.actions;
    if (pos) {
        return parseAction(actions[pos]);
    }

    if (widget.type === MENUNAV_WIDGET) {
        const items = JSON.parse(widget.data).items;
        let posItem = null;
        for (let index = 0; index < items.length; index++) {
            const element = items[index];
            if (focus.value.current?.indexOf(element.id) > -1) {
                posItem = index;
            }
        }
        actions = posItem !== null ? [JSON.parse(widget.actions).actions[posItem]] : null;
    }
    if (!actions?.[0]) {
        return null;
    }

    action = parseAction(actions[0]);
    return action;
};

const parseAction = (action) => {
    let _actionParsed = {};
    switch (action.type) {
        case "section":
            if (action.value === "landing") {
                _actionParsed.layout = "HOME";
            } else if (action.value?.indexOf(":") > -1) {
                const layoutAndId = action.value.split(":");
                _actionParsed.layout = layoutAndId[0];
                _actionParsed.id = layoutAndId[1];
            } else {
                _actionParsed.layout = action.value;
            }
            break;

        case "content":
            if (!action.value) {
                return null;
            }
            _actionParsed.layout = "screen";
            _actionParsed.id = action.value;
            break;
        default:
            if (!action.value) {
                _actionParsed = null;
            } else {
                _actionParsed.layout = action.type;
                _actionParsed.id = action.value;
            }
            break;
    }
    return _actionParsed;
};

const isNavigable = (widget) => {
    return Array("CONTAINER", "LANGSELECTION", "TVMENUNAV").indexOf(widget.type) === -1;
};

export const parseMenuColorsAndAnimation = (menuStyle, menuData) => {
    if (!menuStyle && !menuData) {
        return null;
    }
    let colors = {},
        animations = {};
    //COLORS
    const keyStylesName = [
        { style: "bgColor", theme: "defaultBackground", parsed: "defaultBackground" },
        { style: "fgColor", theme: "defaultForeground", parsed: "defaultForeground" },
        { style: "iconColor", theme: "defaultForeground", parsed: "iconColor" },
        { style: "bgActiveColor", theme: "activeBackground", parsed: "activeBackground" },
        { style: "fgActiveColor", theme: "activeForeground", parsed: "activeForeground" },
        { style: "iconActiveColor", theme: "activeForeground", parsed: "iconActiveColor" },
        { style: "focusBgColor", theme: "focusBackground", parsed: "focusBackground" },
        { style: "fgFocusColor", theme: "focusForeground", parsed: "focusForeground" },
        { style: "iconFocusColor", theme: "focusForeground", parsed: "iconFocusColor" },
    ];

    for (let index = 0; index < keyStylesName.length; index++) {
        const key = keyStylesName[index];
        if (menuStyle?.[key.style] && menuStyle[key.style] !== "") {
            colors[key.parsed] = menuStyle[key.style];
        } else {
            colors[key.parsed] = Theme.colors[key.theme];
        }
    }

    if (menuData && Object.keys(menuData).indexOf("zoomInOutAnimation")) {
        animations.zoomInOutAnimation = menuData.zoomInOutAnimation;
    }
    return { colors, animations };
};

export const salesContentEmpty = (channel, categories, subcategories) => {
    if (
        subcategories &&
        (subcategories.length === 0 ||
            !subcategories?.filter((subcategorie) => subcategorie.productVariants.items.length > 0)[0])
    ) {
        return true;
    }
    if (channel.name === "NOTFOUND" || categories.length === 0) {
        return true;
    }
    return false;
};

export const resetMenuScrollValues = (direction) => {
    const LEFT_RIGHT = [KEYS.left, KEYS.right];
    const UP_DOWN = [KEYS.up, KEYS.down];
    Object.keys(window.MenuRender.tvmenunavOffSetToApply).forEach((menutvId) => {
        if (window.MenuRender?.activeMenuOffSet?.[menutvId]) {
            window.MenuRender.tvmenunavOffSetToApply[menutvId] = window.MenuRender.activeMenuOffSet[menutvId];
        } else {
            window.MenuRender.tvmenunavOffSetToApply[menutvId] = 0;
        }
    });
};
