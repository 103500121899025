import { Link } from "wouter/preact";
import { resetAllQueryCache } from "../../../hooks/query";
import HorizontalList from "../../reusable/horizontalList";
import BackgroundImage from "../../reusable/backgroundImage";
import focus from "../../../utils/focus";
import { carrito } from "../signals/carrito";
import dbStylesToStylesheet from "../../../utils/dbStylesToStylesheet";
import { screenUtils } from "../../../utils/screenUtils";
import storage from "../../../utils/storage";
import { Roomshops } from "sales-layout-library/tv";
import { useEffect, useState } from "preact/hooks";
import { eventHandler } from "../../../utils/eventHandler";
import { navigate } from "wouter/use-location";
import api from "../api";
import salesNoContentComponent from "./salesNoContent";

const RoomshopComp = ({ config }) => {
    const [myShops, setShops] = useState(null);
    const [myDesign, setMyDesign] = useState(null);

    useEffect(() => {
        Promise.all([api.shops(config.showLoadingContent), api.design(config.landingPage.designID)])
            .then(([shops, design]) => {
                setShops(shops);
                setMyDesign(design);
                config.showLoadingContent(false);
            })
            .catch((e) => {
                resetAllQueryCache();
            });
    }, []);

    if (!myShops && !myDesign) {
        config.showLoadingContent(true);
        return null;
    }

    if (myShops?.length === 0) {
        return salesNoContentComponent(myDesign?.style, "shop not availabe");
    }
    if (myShops?.length === 1) {
        eventHandler.skipNextPath(); //this should be added if replace is trye in navigate options
        navigate(`/roomshops/${myShops[0].token}/categories`, { replace: true });
        return;
    }

    return (
        <div id="sales">
            <Roomshops
                data={myShops}
                templating={{
                    css: dbStylesToStylesheet(myDesign?.style),
                    strings: {
                        welcomeMessage: screenUtils.replaceSpecialTags(myDesign?.title?.text ?? "", config.tags),
                        showMyOrders: myDesign?.ordersButton,
                        welcomeTextAlignment: myDesign?.textAlignment,
                    },
                    texts: config.texts,
                    languageCode: storage.get("lang", "en"),
                    projectLanguageCode: config.projectLanguageCode,
                }}
                signals={{ focus, cart: carrito.value ?? [] }}
                injected={{
                    Link,
                    List: HorizontalList,
                    BackgroundImage,
                }}
            />
        </div>
    );
};

export default RoomshopComp;
