import { eventHandler } from "../utils/eventHandler";

export const SHOW_MENU = "UI_SHOW_MENU";
export const SHOW_MESSAGES = "UI_SHOW_MESSAGES";
export const SHOW_LOADING = "UI_SHOW_LOADING";
export const SET_POPUP = "UI_SET_ON_POPUP";
export const POP_LAYOUT_HISTORY = "UI_POP_LAYOUT_HISTORY";
export const SET_TEXTS = "UI_SET_TEXTS";
export const SET_VIDEO_POS = "UI_SET_VIDEO_POS";
export const NAVIGATE_TO = "UI_NAVIGATE_TO";
export const SET_LANDING = "UI_SET_LANDING";
export const SET_BACKGROUND = "UI_SET_BACKGROUND";
export const FORCE_BACKGROUND_RESTORE = "FORCE_BACKGROUND_RESTORE";
export const SET_LANDING_BACKGROUND = "UI_SET_LANDING_BACKGROUND";
export const SET_MENU_LAST_FOCUS = "UI_SET_MENU_LAST_FOCUS";
export const SET_MENU_TEMPORARY_TYPE = "UI_SET_MENU_TEMPORARY_TYPE";
export const SET_FLAG_WIGETS_POS = "SET_FLAG_WIGETS_POS";
export const SET_POPUP_ERROR = "SET_POPUP_ERROR";
export const SET_APP_VISIBILITY = "SET_APP_VISIBILITY";
export const RENEW_SIGNAGE = "RENEW_SIGNAGE";

export function displayMenu(val) {
    return (dispatch) => {
        dispatch({
            type: SHOW_MENU,
            payload: val,
        });
    };
}

export function setPopUp(val) {
    return (dispatch) => {
        eventHandler.blockMovement(500);
        dispatch({
            type: SET_POPUP,
            payload: val,
        });
    };
}

export function showLoading(val) {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            payload: val,
        });
    };
}

export function setTexts(lang) {
    return (dispatch) => {
        dispatch({
            type: SET_TEXTS,
            payload: lang,
        });
    };
}

export function setVideoPos(pos) {
    return (dispatch) => {
        dispatch({
            type: SET_VIDEO_POS,
            payload: pos,
        });
    };
}
export function navigateTo(to) {
    return (dispatch) => {
        dispatch({
            type: NAVIGATE_TO,
            payload: to,
        });
    };
}

export function setLanding(landing) {
    return (dispatch) => {
        dispatch({
            type: SET_LANDING,
            payload: landing,
        });
    };
}

export function setBackground(bg) {
    return (dispatch) => {
        dispatch({
            type: SET_BACKGROUND,
            payload: bg,
        });
    };
}
export function forceBackgroundRestore() {
    return (dispatch) => {
        dispatch({
            type: FORCE_BACKGROUND_RESTORE,
            payload: new Date().getTime(),
        });
    };
}

export function setLandingBackground(bg) {
    return (dispatch) => {
        dispatch({
            type: SET_LANDING_BACKGROUND,
            payload: bg,
        });
    };
}

export function setMenuLastFocus(element) {
    return (dispatch) => {
        dispatch({
            type: SET_MENU_LAST_FOCUS,
            payload: element,
        });
    };
}

export function setMenuTemporaryType(type) {
    return (dispatch) => {
        dispatch({
            type: SET_MENU_TEMPORARY_TYPE,
            payload: type,
        });
    };
}

export function setFlagWidgetsPos(boolean) {
    return (dispatch) => {
        dispatch({
            type: SET_FLAG_WIGETS_POS,
            payload: boolean,
        });
    };
}

export function displayErrorPopUp(errorData) {
    return (dispatch) => {
        dispatch({
            type: SET_POPUP_ERROR,
            payload: errorData,
        });
    };
}
export function setAppVisibility(visibility) {
    return (dispatch) => {
        dispatch({
            type: SET_APP_VISIBILITY,
            payload: visibility,
        });
    };
}
export function setRenewSignage(boolean) {
    return (dispatch) => {
        dispatch({
            type: RENEW_SIGNAGE,
            payload: boolean,
        });
    };
}
