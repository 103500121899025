import Loading from "../../loading";
import api from "../api";
import { Link } from "wouter/preact";
import Asyncable from "../../reusable/asyncable";
import { MyOrders } from "sales-layout-library/tv";
import dbStylesToStylesheet from "../../../utils/dbStylesToStylesheet";
import { resetVendureCache, vendureCacheCleanup } from "../../../hooks/query";
import VerticalList from "../../reusable/verticalList";
import focus from "../../../utils/focus";
import storage from "../../../utils/storage";
import { LOADING_STYLE } from "../../../utils/constants";
import { useEffect, useState } from "preact/hooks";

const MyOrdersComp = ({ config }) => {
    const [myOrders, setMyOrders] = useState(null);
    const [myDesign, setMyDesign] = useState(null);

    useEffect(() => {
        Promise.all([api.myOrders(config.allShopsTokens), api.design(config.landingPage.designID)])
            .then(([_myOrders, design]) => {
                Promise.resolve(
                    api.orderInfo(
                        config.allShopsTokens,
                        _myOrders.map((item) => item.id),
                    ),
                ).then((orderInfo) => {
                    const ordersInfo = orderInfo?.data?.orderInfo;
                    _myOrders.forEach((order) => {
                        const _orderInfoData = ordersInfo.find((item) => parseInt(item.id) === parseInt(order.id));
                        if (_orderInfoData?.customFields?.pickUp?.location) {
                            order.channels[0].customFields.pickup_location = `[{"languageCode":"en","name":"${_orderInfoData.customFields.pickUp.location}"}]`;
                        }
                    });
                    setMyOrders(_myOrders);
                    setMyDesign(design);
                    config.showLoadingContent(false);
                });
            })
            .catch((e) => {
                vendureCacheCleanup();
            });
    }, []);

    if (!myOrders) {
        config.showLoadingContent(true);
        return null;
    }

    return (
        <div id="sales">
            <MyOrders
                data={myOrders}
                templating={{
                    css: dbStylesToStylesheet(myDesign?.style),
                    texts: config.texts,
                    languageCode: storage.get("lang", "en"),
                    projectLanguageCode: config.projectLanguageCode,
                }}
                signals={{ focus }}
                injected={{
                    Link,
                    List: VerticalList,
                    cleanCache: resetVendureCache,
                }}
            />
        </div>
    );
};

export default MyOrdersComp;
