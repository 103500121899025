import Button from "../common/button";
import Modal from "../common/modal";
import { Texts } from "../../utils/texts";
import { useDispatch, useSelector } from "react-redux";
import { displayErrorPopUp, setPopUp } from "../../actions/uiActions";
import { STB } from "../../hooks/apis/stb";
import { TV_MODELS } from "../../utils/constants";
import { Logger } from "../../utils/logger";
import Query from "../../hooks/query";

export const ResetCredentials = ({ success = false }) => {
    const dispatch = useDispatch();
    const texts = useSelector((state) => state.ui.texts);
    const locationData = useSelector((state) => state.status.locationData);

    const title = success ? "reset-successful" : "reset-credentials";
    const btnText = success ? "close" : "reset";

    const startCDProcess = () => {
        Query({
            query: `mutation{
                        resetRoomTvCredentials{
                            ok
                            error{
                                code
                                msg
                            }
                        }
            }`,
            onResponse(res) {
                dispatch(setPopUp(null));
                if (res?.data?.resetRoomTvCredentials?.ok && 1 === 2) {
                    localStorage.setItem("deletingCredentialsOnMultipleDevices", new Date().getTime());
                    STB.resetCredentials();
                } else {
                    dispatch(
                        displayErrorPopUp({ text: Texts.capitalize(texts["reset-credentials-error"]), timeout: 3000 }),
                    );
                    Logger.remoteLog("Error on resetRoomTvCredentials");
                }
            },
        });
    };

    return (
        <Modal
            width="69.583vw"
            height="62.222vh"
            backBtn={success ? false : true}
            backBtnClass={"mb-4"}
            title={Texts.capitalize(texts[title])}
            titleClass={`text-center mt-8 mb-8 text-4xl ${success ? "pt-8" : ""}`}
            customClass="text-center"
            firstFocus={`btn-${btnText}`}
            body={
                <>
                    <div class={`text-xl text-center w-full text-2xl`} style={{ height: "30vh" }}>
                        <div>
                            {success
                                ? Texts.capitalize(texts["reset-successful-msg"])
                                : Texts.capitalize(texts["reset-credentials-warning-1"])}
                        </div>
                        {!success && <div class={"mt-8"}>{Texts.capitalize(texts["reset-credentials-warning-2"])}</div>}
                        {!success && (
                            <div class={"mt-8 pt-8"}>{Texts.capitalize(texts["reset-credentials-warning-3"])} ...</div>
                        )}
                    </div>
                    <div style={{ bottom: 0, display: "flex" }}>
                        <div class={"py-6 mx-auto "} style={{ width: "44.583vw" }}>
                            <Button
                                insideModal={true}
                                data={{
                                    id: btnText,
                                    name: Texts.capitalize(texts[btnText]),
                                    border: true,
                                    customClass: " py-2 px-4 text-lg text-center rounded float-left",
                                    customStyle: {
                                        width: "100%",
                                    },
                                    onClick() {
                                        if (success) {
                                            dispatch(setPopUp(null));
                                        } else {
                                            //must ensure credential deletion on both devices
                                            if (STB.hasGoogleTVConnected()) {
                                                startCDProcess();
                                                return;
                                            }

                                            STB.resetCredentials();
                                            if (STB.model !== TV_MODELS.GOOGLE_TV) {
                                                dispatch(setPopUp("resetCredentials-success"));
                                            }
                                        }
                                    },
                                }}
                            />
                        </div>
                    </div>
                </>
            }
        />
    );
};
