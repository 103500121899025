/* eslint-disable no-undef */

export const moveFocus = (currentFocusId, direction) => {
    let elemToFocus,
        offsetToApply,
        previousListElement,
        nextListElement = null;
    const SCROLL_FACTOR = 5;

    const elementOnFocus = document.querySelector(`[id='${currentFocusId}']`);
    if (!elementOnFocus) {
        return;
    }
    let curMargin = null;
    let prevOffSet = 0;
    switch (direction) {
        case "left":
            if (elementOnFocus.parentNode.classList.contains("horizontalListPaginated")) {
                previousListElement = elementOnFocus?.previousSibling || null;
                if (previousListElement && isElementVisible(previousListElement)) {
                    return previousListElement.id;
                } else if (!previousListElement) {
                    return `horizontalList_${elementOnFocus.parentNode.id}_move_left`;
                }
            } else if (elementOnFocus.parentNode.classList.contains("horizontalList")) {
                previousListElement = elementOnFocus?.previousSibling?.previousSibling || null;
                if (previousListElement) {
                    if (!isElementVisible(previousListElement)) {
                        //search element with scroll for scroll it
                        if (elementOnFocus.parentNode.style.marginLeft) {
                            prevOffSet = Number(removePXchars(elementOnFocus.parentNode.style.marginLeft));
                        }
                        if (!curMargin) {
                            curMargin = 0;
                        }

                        offsetToApply =
                            prevOffSet + previousListElement.offsetWidth + getBorderXOffset(previousListElement);

                        offsetToApply = Number(offsetToApply);
                        offsetToApply = curMargin + offsetToApply;
                        if (elementOnFocus.getAttribute("menuNavItem") === "true") {
                            window.MenuRender.tvmenunavOffSetToApply[elementOnFocus.parentNode.id] =
                                -Math.abs(offsetToApply);
                        } else {
                            elementOnFocus.parentNode.style.marginLeft = `${offsetToApply > 0 ? 0 : offsetToApply}px`;
                        }
                    }
                    return elementOnFocus.previousSibling.id;
                } else if (elementOnFocus.previousSibling && !previousListElement) {
                    window.MenuRender.tvmenunavOffSetToApply[elementOnFocus.parentNode.id] = 0;
                    return elementOnFocus.previousSibling.id;
                }
            }

            //////////////////////////////////////////////////
            // Look for the next element to focus using offset
            //////////////////////////////////////////////////

            elemToFocus = getNextElementByOffset("left", false, elementOnFocus);

            if (elemToFocus && containClass(elemToFocus, "navigable")) {
                return elemToFocus.id;
            }
            ////////////////////////////////////////////////////
            break;
        case "right":
            if (elementOnFocus.parentNode.classList.contains("horizontalListPaginated")) {
                nextListElement = elementOnFocus?.nextSibling || null;
                if (nextListElement && isElementVisible(nextListElement)) {
                    return nextListElement.id;
                } else if (!nextListElement) {
                    return `horizontalList_${elementOnFocus.parentNode.id}_move_right`;
                }
            } else if (elementOnFocus.parentNode.classList.contains("horizontalList")) {
                nextListElement = elementOnFocus?.nextSibling?.nextSibling;
                if (nextListElement && !isElementVisible(nextListElement)) {
                    //search element with scroll for scroll it
                    if (elementOnFocus.parentNode.style.marginLeft) {
                        prevOffSet = Number(removePXchars(elementOnFocus.parentNode.style.marginLeft));
                    }
                    if (!curMargin) curMargin = 0;

                    offsetToApply = prevOffSet - nextListElement.offsetWidth + getBorderXOffset(nextListElement);
                    offsetToApply = Number(offsetToApply);
                    offsetToApply = curMargin - offsetToApply;

                    if (elementOnFocus.getAttribute("menuNavItem") === "true") {
                        window.MenuRender.tvmenunavOffSetToApply[elementOnFocus.parentNode.id] =
                            -Math.abs(offsetToApply);
                    } else {
                        elementOnFocus.parentNode.style.marginLeft = `${offsetToApply}px`;
                    }

                    return elementOnFocus.nextSibling.id;
                } else if (elementOnFocus.nextSibling) {
                    return elementOnFocus.nextSibling.id;
                } else if (!elementOnFocus.nextSibling) {
                    elemToFocus = getNextElementByOffset("right", false, elementOnFocus.parentNode.parentNode);
                    if (elemToFocus && containClass(elemToFocus, "navigable")) {
                        return elemToFocus.id;
                    }
                }
            }
            //////////////////////////////////////////////////
            // Look for the next element to focus using offset
            //////////////////////////////////////////////////

            elemToFocus = getNextElementByOffset("right", false, elementOnFocus);
            if (elemToFocus && containClass(elemToFocus, "navigable")) {
                return elemToFocus.id;
            }
            ////////////////////////////////////////////////////
            break;
        case "up":
            if (elementOnFocus.parentNode.classList.contains("verticalList")) {
                previousListElement = elementOnFocus?.previousSibling?.previousSibling || null;
                if (previousListElement) {
                    if (!isVerticalElementVisible(previousListElement)) {
                        //search element with scroll for scroll it

                        if (elementOnFocus.parentNode.style.marginTop) {
                            prevOffSet = Number(removePXchars(elementOnFocus.parentNode.style.marginTop));
                        }
                        offsetToApply =
                            prevOffSet + previousListElement.offsetHeight + getBorderOffset(previousListElement);

                        if (elementOnFocus.getAttribute("menuNavItem") === "true") {
                            window.MenuRender.tvmenunavOffSetToApply[elementOnFocus.parentNode.id] =
                                -Math.abs(offsetToApply);
                        } else {
                            elementOnFocus.parentNode.scrollTop =
                                elementOnFocus.parentNode.scrollTop - Math.abs(offsetToApply);
                        }
                    }
                    return elementOnFocus.previousSibling.id;
                } else if (elementOnFocus.previousSibling && !previousListElement) {
                    if (window?.MenuRender?.tvmenunavOffSetToApply?.[elementOnFocus.parentNode.id]) {
                        window.MenuRender.tvmenunavOffSetToApply[elementOnFocus.parentNode.id] = 0;
                    }
                    return elementOnFocus.previousSibling.id;
                }
                elemToFocus = getNextElementByOffset("up", false, elementOnFocus);
                if (elemToFocus && containClass(elemToFocus, "navigable")) {
                    return elemToFocus.id;
                }
                break;
            } else if (elementOnFocus.classList.contains("scrollable")) {
                if (elementOnFocus.children[0].scrollTop > 0) {
                    offsetToApply = elementOnFocus.offsetHeight / SCROLL_FACTOR;

                    elementOnFocus.children[0].scrollTop -= offsetToApply;
                    return;
                } else if (elementOnFocus.getAttribute("data-keysblockedonscroll")?.indexOf("up") > -1) {
                    return;
                }
            }

            //////////////////////////////////////////////////
            // Look for the next element to focus using offset
            //////////////////////////////////////////////////
            elemToFocus = getNextElementByOffset("up", false, elementOnFocus);
            //if elemToFocus found, set focus on it
            if (elemToFocus && containClass(elemToFocus, "navigable")) {
                return elemToFocus.id;
            }
            //////////////////////////////////////////////////

            break;
        case "down":
            if (elementOnFocus.parentNode.classList.contains("verticalList")) {
                nextListElement = elementOnFocus?.nextSibling?.nextSibling || null;
                if (nextListElement) {
                    if (!isVerticalElementVisible(nextListElement)) {
                        //search element with scroll for scroll it
                        if (elementOnFocus.parentNode.style.marginTop) {
                            prevOffSet = Number(removePXchars(elementOnFocus.parentNode.style.marginTop));
                        }
                        offsetToApply = prevOffSet - nextListElement.offsetHeight - getBorderOffset(nextListElement);

                        if (elementOnFocus.getAttribute("menuNavItem") === "true") {
                            window.MenuRender.tvmenunavOffSetToApply[elementOnFocus.parentNode.id] =
                                -Math.abs(offsetToApply);
                        } else {
                            elementOnFocus.parentNode.scrollTop =
                                elementOnFocus.parentNode.scrollTop + Math.abs(offsetToApply);
                        }
                    }
                    return elementOnFocus.nextSibling.id;
                } else if (elementOnFocus.nextSibling) {
                    return elementOnFocus.nextSibling.id;
                }
            } else if (elementOnFocus.classList.contains("scrollable")) {
                if (
                    elementOnFocus.children[0].scrollHeight - elementOnFocus.children[0].scrollTop - 10 >=
                    elementOnFocus.children[0].clientHeight
                ) {
                    offsetToApply = elementOnFocus.offsetHeight / SCROLL_FACTOR;

                    elementOnFocus.children[0].scrollTop += offsetToApply;
                    return;
                } else if (elementOnFocus.getAttribute("data-keysblockedonscroll")?.indexOf("down") > -1) {
                    return;
                }
            }
            //////////////////////////////////////////////////
            // Look for the next element to focus using offset
            //////////////////////////////////////////////////
            elemToFocus = getNextElementByOffset("down", false, elementOnFocus);
            if (elemToFocus && containClass(elemToFocus, "navigable")) {
                return elemToFocus.id;
            }
            //////////////////////////////////////////////////

            break;
    }
};

export const getMenuNavigableItemsBoundingClientRect = () => {
    setTimeout(() => {
        for (let index = 0; index < window.MenuRender.menuWidgetsPos.length; index++) {
            const widgetPos = window.MenuRender.menuWidgetsPos[index];
            let element = document.getElementById(window.MenuRender.divPrefix + widgetPos.ID);
            if (element) {
                widgetPos.boundingClient = element.getBoundingClientRect();
            }
        }
    }, 300);
};

const getNextElementByOffset = (direction, nolimits, elementOnFocus) => {
    if (!elementOnFocus) {
        return;
    }
    let elemToFocus = null;
    let parentToFocus = null;
    let bodyOffsets = document.body.getBoundingClientRect();
    const focusPosis = elementOnFocus.getBoundingClientRect();
    let elemOffset = focusPosis.x ? focusPosis : getelementOffset(elementOnFocus);
    let offsetLeft = null;
    let offsetTop = null;
    let offsetInc = 0;

    let _width = elementOnFocus.clientWidth;
    let _height = elementOnFocus.clientHeight;
    let _left = elemOffset.x;
    let _top = elemOffset.y + (direction === "down" ? _height : 0);

    if (direction === "up" || direction === "down") {
        if (!containClass(elementOnFocus, "left-bounding")) {
            _left += _width / 2;
        }
        if (nolimits) {
            _left = 0;
            _width = document.body.offsetWidth;
        }
    } else if (direction === "right" || direction === "left") {
        _top += _height / 2;
        if (nolimits) {
            _top = 0;
            _height = document.body.offsetHeight;
        }
    }

    if (direction === "right" || direction === "left") {
        while (offsetInc <= _height) {
            if (direction === "right") {
                offsetLeft = _left + 30 + _width;
                offsetTop = _top;
            }
            if (direction === "left") {
                offsetLeft = _left - 30;
                offsetTop = _top;
            }
            while (offsetLeft <= bodyOffsets.width) {
                elemToFocus = document.elementFromPoint(offsetLeft, offsetTop + offsetInc);

                if (elemToFocus && (elemToFocus === elementOnFocus || !containClass(elemToFocus, "navigable"))) {
                    //check parent to focus
                    parentToFocus = closestElement(elemToFocus, "navigable");
                    if (parentToFocus && parentToFocus !== elementOnFocus) {
                        elemToFocus = parentToFocus;
                        return elemToFocus;
                    }
                    if (direction === "right") {
                        offsetLeft = offsetLeft + 30;
                    }
                    if (direction === "left") {
                        offsetLeft = offsetLeft - 30;
                    }
                } else break;
            }
            offsetInc = offsetInc + elementOnFocus.clientHeight / 4;
            if (containClass(elemToFocus, "navigable")) break;
        }
    } else if (direction === "up" || direction === "down") {
        while (offsetInc <= _width) {
            //depending on the key pressed, increment or decrement the element x/y offset to look for
            //next navigable element by loop
            offsetTop = null;
            if (direction === "up") {
                offsetTop = _top - 25;
            }
            if (direction === "down") {
                offsetTop = _top + 25;
            }
            let tmpElement;
            while (direction === "up" ? offsetTop > -1 : offsetTop <= bodyOffsets.height) {
                //get the element at a specific coordinates
                tmpElement = document.elementFromPoint(_left + 10 + offsetInc, offsetTop);
                //if the element is already focused or is not navigable
                if (tmpElement && (tmpElement === elementOnFocus || !containClass(tmpElement, "navigable"))) {
                    //for the element in new coordinates, get if any parent is navigable
                    parentToFocus = closestElement(tmpElement, "navigable");
                    if (parentToFocus && parentToFocus !== elementOnFocus) {
                        tmpElement = parentToFocus;
                        elemToFocus = tmpElement;
                        return elemToFocus;
                    }

                    if (containClass(tmpElement, "containNavigable")) {
                        //check children to focus
                        if (tmpElement != elementOnFocus) {
                            let childToFocus = childElement(tmpElement, "navigable");
                            if (childToFocus && childToFocus != elementOnFocus) {
                                tmpElement = childToFocus;
                                elemToFocus = tmpElement;
                                break;
                            }
                        }
                    }

                    if (direction === "up") {
                        offsetTop = offsetTop - 25;
                    }
                    if (direction === "down") {
                        offsetTop = offsetTop + 25;
                    }
                } else break;
            }
            if (nolimits) {
                offsetInc += 25;
            } else {
                offsetInc = offsetInc + _width / 4;
            }

            if (containClass(tmpElement, "navigable")) {
                elemToFocus = tmpElement;
                break;
            }
        }
    }
    if ((!elemToFocus || !containClass(elemToFocus, "navigable")) && nolimits !== true) {
        return getNextElementByOffset(direction, true, elementOnFocus);
    }

    return elemToFocus;
};

/*
 * Return the closest ancestor contain className
 */
export const closestElement = (elem, className, deep) => {
    if (deep) {
        deep++;
    }
    if (deep == 50) {
        return null;
    }
    if (!elem || !className || elem === document.body) return null;
    if (elem.parentNode?.className && elem.parentNode.classList.contains(className)) {
        return elem.parentNode;
    }
    if (!elem.parentNode || elem.className.indexOf("body") > -1) {
        return null;
    }

    let closest = closestElement(elem.parentNode, className, deep ? deep : 1);
    if (closest) {
        return closest;
    }
};

/*
 * Loop the children of elem to look for an element with the specified className
 */
const childElement = (elem, className, deep) => {
    if (deep) {
        deep++;
    }
    if (deep == 50) {
        return null;
    }
    if (!elem || !className || elem === document.body) {
        return null;
    }
    if (containClass(elem, className)) {
        return elem;
    }
    let childNodes = elem.children;
    if (childNodes && childNodes.length > 0) {
        for (let index = 0; index < childNodes.length; index++) {
            const child = childNodes[index];
            let childElem = childElement(child, className, deep ? deep : 1);
            if (childElem) {
                return childElem;
            }
        }
    }
    return null;
};

const getelementOffset = (el) => {
    let topScroll = null;
    if (el?.parentNode?.classList.contains("verticalList")) {
        topScroll = el.parentNode.scrollTop;
    }

    let lx = 0,
        ly = 0;

    while (el !== null) {
        lx += el.offsetLeft;
        ly += el.offsetTop;
        el = el.offsetParent;
    }

    if (topScroll) {
        ly = ly - topScroll;
    }
    return { x: lx, y: ly };
};

const containClass = (elem, classname) => {
    if (!elem || (elem && !elem.className)) {
        return false;
    }

    if (elem.classList && typeof elem.classList.contains !== "undefined") {
        return elem.classList.contains(classname);
    }
    return elem.className.indexOf(classname) > -1;
};

const isVerticalElementVisible = (elem) => {
    let elemOffset = getelementOffset(elem);

    for (let inc = 0; inc <= elem.offsetHeight - 2; inc = inc + 10) {
        let elemPointed = document.elementFromPoint(elemOffset.x + elem.offsetWidth / 2, elemOffset.y + inc);
        if (elemPointed && containClass(elemPointed, "scrolling_banner")) {
            continue;
        }
        let elemNavi = containClass(elemPointed, "navigable") ? elemPointed : closestElement(elemPointed, "navigable");
        if ((elemNavi && elemNavi !== elem) || !elemNavi) {
            return false;
        }
    }
    return true;
};

//check several position of an element to see if all of them are visible
export const isElementVisible = (elem) => {
    if (!elem) {
        return;
    }

    if (elem.offsetParent === null) {
        // Element is not visible (display:none maybe)
        return false;
    }

    let elemOffset = getelementOffset(elem);

    //comprobar visibilidad del elemento - mejora:  uso de cuatro puntos, los vertices del elemento
    // POINTS    [arriba-izq, arriba-drch, abajo-drch, abajo-izq]
    const getMarginOfElement = (_element, side) => {
        if (side === "left" || side === "right") {
            return getBorderXOffset(_element) / 2;
        }
        if (side === "top" || side === "bottom") {
            return getBorderOffset(_element) / 2;
        }
        return 0;
    };
    const POINTS = [
        {
            x: elemOffset.x + getMarginOfElement(elem, "left") + 5,
            y: elemOffset.y + getMarginOfElement(elem, "top") + 5,
        },
        {
            x: elemOffset.x + elem.offsetWidth - getMarginOfElement(elem, "right") - 5,
            y: elemOffset.y + getMarginOfElement(elem, "top") + 5,
        },
        {
            x: elemOffset.x + elem.offsetWidth - getMarginOfElement(elem, "right") - 5,
            y: elemOffset.y + elem.offsetHeight - getMarginOfElement(elem, "bottom") - 5,
        },
        {
            x: elemOffset.x + getMarginOfElement(elem, "left") + 5,
            y: elemOffset.y + elem.offsetHeight - getMarginOfElement(elem, "bottom") - 5,
        },
    ];

    for (let p = 0; p < POINTS.length; p++) {
        const point = POINTS[p];
        const elemPointed = document.elementFromPoint(point.x, point.y);
        const elemNavi = containClass(elemPointed, "navigable")
            ? elemPointed
            : closestElement(elemPointed, "navigable");
        if ((elemNavi && elemNavi !== elem) || !elemNavi) {
            return false;
        }
    }
    return true;
};

const getBorderOffset = (elem) => {
    if (!elem) {
        return;
    }
    let offset = 0;
    offset = offset + Number(window.getComputedStyle(elem).getPropertyValue("margin-top").replace("px", ""));
    offset = offset + Number(window.getComputedStyle(elem).getPropertyValue("margin-bottom").replace("px", ""));
    offset = offset + Number(window.getComputedStyle(elem).getPropertyValue("border-top-width").replace("px", ""));
    offset = offset + Number(window.getComputedStyle(elem).getPropertyValue("border-bottom-width").replace("px", ""));

    return offset;
};

const getBorderXOffset = (elem) => {
    if (!elem) {
        return;
    }
    let offset = 0;
    offset = offset + Number(window.getComputedStyle(elem).getPropertyValue("margin-right").replace("px", ""));
    offset = offset + Number(window.getComputedStyle(elem).getPropertyValue("margin-left").replace("px", ""));
    offset = offset + Number(window.getComputedStyle(elem).getPropertyValue("border-left-width").replace("px", ""));
    offset = offset + Number(window.getComputedStyle(elem).getPropertyValue("border-right-width").replace("px", ""));

    return offset;
};

const removePXchars = (text) => {
    return text.replace("px", "");
};
