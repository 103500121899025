import { useEffect, useState } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import { vendureCacheCleanup } from "../../../hooks/query";
import { Link } from "wouter/preact";
import { Autoscrollable } from "../../reusable/autoscrollable";
import storage from "../../../utils/storage";
import { MyOrderDetail } from "sales-layout-library/tv";
import dbStylesToStylesheet from "../../../utils/dbStylesToStylesheet";
import focus from "../../../utils/focus";
import api from "../api";

const MyOrdersDetailComp = ({ params, config }) => {
    const dispatch = useDispatch();

    const [shop, setShop] = useState(null);
    const [orderDetail, setOrderDetail] = useState(null);
    const [myDesign, setMyDesign] = useState(null);

    useEffect(() => {
        Promise.all([
            api.shops(),
            api.orderById(config.allShopsTokens, [params.order]),
            api.orderInfo(config.allShopsTokens, [params.order]),
            api.design(config.landingPage.designID),
        ])
            .then(([shops, ordersById, orderInfo, design]) => {
                config.showLoadingContent(false);
                const _orderDetail = ordersById?.data?.orders?.items?.[0] || null;
                let shopData = shops.find((_shop) => _shop.token === _orderDetail.channels[0].token);
                const orderInfoData = orderInfo?.data?.orderInfo;
                const orderExtraDetail = orderInfoData.find((order) => parseInt(order.id) === parseInt(params.order));
                _orderDetail.productsData = orderExtraDetail.customFields.items;
                if (orderExtraDetail?.customFields?.pickUp?.location) {
                    shopData.customFields.pickup_location = [
                        {
                            languageCode: "en",
                            name: orderExtraDetail.customFields.pickUp.location,
                        },
                    ];
                }
                setShop(shopData);
                setOrderDetail(_orderDetail);
                myDesign(design);
            })
            .catch((e) => {
                vendureCacheCleanup();
            });
    }, []);

    if (!shop && !orderDetail) {
        config.showLoadingContent(true);
        return null;
    }

    return (
        <div id="sales">
            <MyOrderDetail
                data={{ shop: shop, order: orderDetail }}
                templating={{
                    css: dbStylesToStylesheet(myDesign?.style),
                    texts: config.texts,
                    languageCode: storage.get("lang", "en"),
                    projectLanguageCode: config.projectLanguageCode,
                }}
                signals={{ focus }}
                injected={{
                    Autoscrollable,
                    Link,
                    cancelOrder: () => {
                        storage.set("orderToken", shopData.token);
                        dispatch(setPopUp("cancelorder"));
                    },
                }}
            />
        </div>
    );
};

export default MyOrdersDetailComp;
