import { useDispatch, useSelector } from "react-redux";
import OutOfContent from "../../components/ContactHotelStaff";
import { Texts } from "../../utils/texts";
import { useEffect, useRef, useState } from "preact/hooks";
import { getSignageSequences } from "../../hooks/GraphqlCalls/Signage/getSignageContent";
import { LOADING_STYLE, MS_INTERVAL } from "../../utils/constants";
import Loading from "../../components/loading";
import PlayCurrentSequence from "./PlayCurrentSequence";
import { getDefaultSequence, isActiveSequence } from "../../utils/signageUtils";
import { SESSION } from "../../utils/session";
import { setBackground, setLandingBackground, setRenewSignage } from "../../actions/uiActions";
import { completeDasURL } from "../../utils/utils";

const Signage = () => {
    const dispatch = useDispatch();
    const texts = useSelector((state) => state.ui.texts);
    const renewSignage = useSelector((state) => state.ui.renewSignage);

    const [loadingSequences, setLoadingSequences] = useState(true);
    // const [sequences, setSequences] = useState(null);
    // const [currentSequence, setCurrentSequence] = useState(null);
    const sequencesRef = useRef(null);
    const currentSequence = useRef(null);
    const checkTimeout = useRef(null);

    const runSignageQuery = () => {
        setLoadingSequences(true);
        getSignageSequences((sequences, landing) => {
            if (sequences) {
                sequencesRef.current = sequences;
                checkCurrentSequence();
            }

            landing?.contentStyle && prepareBackground(landing?.contentStyle);

            setLoadingSequences(false);
        }, dispatch);
    };

    useEffect(() => {
        runSignageQuery();
    }, []);

    useEffect(() => {
        if (renewSignage) {
            currentSequence.current = null;
            runSignageQuery();
            // dispatch(renewSignage(false));
        }
    }, [renewSignage]);

    const prepareBackground = (landingStyle) => {
        //set Default Design BG if exists
        let lStyle = landingStyle;

        lStyle = JSON.parse(lStyle || null);
        if (lStyle) {
            const bgPath =
                lStyle.images[0].externalUrl || completeDasURL(lStyle.images[0].libraryRef, { width: "full" });

            const bgData = {
                image: bgPath,
                bgColor: lStyle?.bgColor ? lStyle.bgColor : null,
                opacity: lStyle?.opacity ? lStyle.opacity / 100 : 100,
            };
            dispatch(setLandingBackground(bgData));
        }
    };

    const setCurrent = (sequence) => {
        if (sequence?.id !== currentSequence?.current?.id || renewSignage) {
            SESSION.currentSignage.name = sequence.name;
            // setCurrentSequence({ ...sequence, contents: sequence.contents.sort((a, b) => a.pos - b.pos) });
            currentSequence.current = { ...sequence, contents: sequence.contents?.sort((a, b) => a.pos - b.pos) };
            if (renewSignage) {
                dispatch(setRenewSignage(false));
            }
        }
    };

    const checkCurrentSequence = () => {
        const activeSequences = sequencesRef.current.filter((item) => isActiveSequence(item).active);
        if (activeSequences.length === 1) {
            setCurrent(activeSequences[0]);
        } else if (activeSequences.length > 0) {
            const activeLevel = activeSequences.map((item) => {
                return { ...item, activeLevel: isActiveSequence(item).level };
            });
            // order by active level and id
            // get higher id of same level
            setCurrent(
                activeLevel.sort((a, b) => {
                    const res = a.activeLevel > b.activeLevel;
                    if (res) {
                        return res;
                    }
                    return b.id - a.id;
                })[0],
            );
        } else {
            // no programmed sequence set DEFAULT
            setCurrent(getDefaultSequence(sequencesRef.current));
        }
        if (checkTimeout.current) {
            clearTimeout(checkTimeout.current);
        }
        // check for any sequence to interrupt current every 1 min

        checkTimeout.current = setTimeout(
            checkCurrentSequence,
            MS_INTERVAL.ONE_MINUTE - new Date().getSeconds() * 1000,
        );
    };

    if (loadingSequences) {
        return <Loading text={Texts.translateCap(texts, "loading") + " signage"} style={LOADING_STYLE} />;
    }
    if (
        !sequencesRef.current ||
        !currentSequence.current?.contents ||
        currentSequence.current?.contents?.length === 0
    ) {
        return <OutOfContent message={Texts.translateCap(texts, "no-signage-created")} />;
    }
    if (!currentSequence.current) {
        return null;
    }
    return <PlayCurrentSequence sequence={currentSequence.current} />;
};

export default Signage;
